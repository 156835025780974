export const priceList = [
    {
        "title": "内容管理",
        "list": [
            { title: "视频课程" },
            { title: "音频课程" },
            { title: "图文课程" },
            { title: "系列课程" },
            { title: "买赠课程" },
            { title: "实物发货课程" },
            { title: "文库素材" },
            { title: "学习资料" },
            { title: "试看课程" },
            { title: "复制课程" },
            { title: "电脑端看课" },
            { title: "防录屏跑马灯" },
            { title: "音频息屏" },
            { title: "课程一键同步" }
        ],
    },
    {
        "title": "挂载功能",
        "list": [
            { title: "短视频挂载" },
            { title: "直播间挂载" },
            { title: "分销挂载" },
            { title: "橱窗挂载", type2: "2" },
            { title: "视频号挂载", type1: "1", type2: "1" },
            { title: "公众号菜单", type1: "1", type2: "1" },
            { title: "公众号菜单", type1: "1", type2: "1" }

        ],
    },
    {
        "title": "营销管理",
        "list": [
            { title: "优惠券" },
            { title: "限时抢购" },
            { title: "达人分销" },
            { title: "定向推广" },
            { title: "自定义短信营销" },
            { title: "挂载合集页" },
            { title: "达人通告" },
            { title: "粉丝群", type1: false,type2:2,type3:2 }
        ],
    },

    {

        "title": "资金管理",
        "list": [
            { title: "资金收益" },
            { title: "退款管理" },
            { title: "投诉管理" },
            { title: "发货订单" },
            { title: "订单统计" },
            { title: "手机端提现" },
            { title: "浏览数" },
            { title: "订单备注" },
            { title: "订单筛选/导出" },
            { title: "订单通知" }
        ]
    },

]

// 关于我们页配置
export const aboutUs = {
    "bottom": [
        "https://file.jinglinshe.com/kittyhow/catgif.gif",
        "https://file.jinglinshe.com/kittyhow/gallery12.png",
        "https://file.jinglinshe.com/kittyhow/xueying.jpg",
        "https://file.jinglinshe.com/kittyhow/gallery19.png",
        "https://file.jinglinshe.com/kittyhow/gakkery90.jpg",
        "https://file.jinglinshe.com/kittyhow/grally73.jpg",
        "https://file.jinglinshe.com/kittyhow/Gallery6.png"
    ],
    "aboutUs": {
        "remarks": "成为知视猫的一员？",
        "tit": "关于我们",
        "desc": "一群渴望分享热爱生活的90后、00后怀揣梦想，筑梦而生。经过几年的用心成长，越来越多的小伙伴加入知视猫的团队。了解需求、助知识创造价值” 是公司发展的初衷，并且一路贯彻执行。        ",
        "iconList": [
            "https://file.jinglinshe.com/kittyhow/semiDesign-semi-icons-tiktok_logo%403x.png",
            "https://file.jinglinshe.com/kittyhow/图片 3@3x.png",
            "https://file.jinglinshe.com/kittyhow/if-wechat@3x.png",
            "https://file.jinglinshe.com/kittyhow/antFill-zhihu-circle@3x.png",
            "https://file.jinglinshe.com/kittyhow/riFill-mail-fill@3x.png"
        ],
        "linkList": [
            "",
            "",
            "",
            "",
            ""
        ],
        "rightIcon": [
            "https://file.jinglinshe.com/kittyhow/Untitled-11.jpg",
            "https://file.jinglinshe.com/kittyhow/Untitled21-11.jpg"
        ]
    },
    "video": "https://file.jinglinshe.com/zsm-about-us-top-video.mp4",
    "distant": [
        {
            "tit": "远景",
            "desc": "成为最受讲师信任和欢迎的知识变现平台",
            "img": "https://file.jinglinshe.com/2bc643c9-9b1e-4fe8-bb7a-772c373a2bc0.png"
        },
        {
            "tit": "使命",
            "desc": "为用户探索和发现更多优质的课程",
            "img": "https://file.jinglinshe.com/d21c3e88-57b5-4a7f-95e4-8b84944b9394.png"
        },
        {
            "tit": "企业价值观",
            "desc": "积极向上、走进讲师和用户、务实、担当",
            "img": "https://file.jinglinshe.com/3c7877b9-032c-4509-8af1-48ae559dbab0.png"
        }
    ],
    "who": {
        tit: "什么是知视猫",
        desc: " 知视猫是一站式知识变现平台，专注泛知识视频变现解决方案的服务商，为达人/机构/企业提供定制化抖音/快手可挂载专属小程序，快速搭建独立知识付费小程序，打造品牌商城，凸显品牌形象。为达人提供从引流、获客，到运营、转化的内容变现全链服务，为用户提供健身瑜伽、亲子教育、商业营销等各个领域的干货技能课程。",
        list: [
            {
                "bg": "https://file.jinglinshe.com/aboutus_2-1.png",
                "sort": "01",
                "tit": "类目",
                "desc": "知视猫平台目前内容覆盖美妆教学、技能培训、软件教学、亲子教育、运动瑜伽、音乐教学、商业管理、数字运营等各个泛知识领域、超过2亿的曝光。"
            },
            {
                "bg": "https://file.jinglinshe.com/aboutus_1.png",
                "sort": "02",
                "tit": "服务",
                "desc": "知视猫的服务围绕“内容创造者”这一核心服务对象，整合泛知识内容变现资源，帮助内容创造者更好的推广课程，提供一站式全链路营销工具和服务。"
            },
            {
                "bg": "https://file.jinglinshe.com/zsm-h5-bg-img6.png",
                "sort": "03",
                "tit": "内容挂载",
                "desc": "知视猫的内容挂载是通过抖音、快手等平台的短视频或直播通道进行课程的挂载和推广。"
            }
        ]
    },
    "usTypeTit": "我们 的方式",
    "usType": [
        {
            "tit": '质量',
            "desc": '我们要求我们自身和团队的小伙伴们要不断提升我们的服务质量',
            "img": 'https://file.jinglinshe.com/kittyhow/ABOUT1.png'
        },
        {
            "tit": '数据法制',
            "desc": '数据说明一切，有了数据信息的支持我们才能不断的前行',
            "img": 'https://file.jinglinshe.com/kittyhow/about2.png'
        },
        {
            "tit": '保持谦逊',
            "desc": '数据说明一切，有了数据信息的支持我们才能不断的前行',
            "img": 'https://file.jinglinshe.com/kittyhow/3-Humble.png'
        },
        {
            "tit": '庆祝',
            "desc": '当我们完成目标后，我们要享受这份庆祝的时光',
            "img": 'https://file.jinglinshe.com/kittyhow/4-Celebrate.png'
        },
        {
            "tit": '质量',
            "desc": '每个人都有提成新的建议或对现有的规则提成建议的权力',
            "img": 'https://file.jinglinshe.com/kittyhow/5-Approachable.png'
        },
        {
            "tit": '质量',
            "desc": '快速相应能让我们有足够的时间去常识新的事物，去学习，去归总经验',
            "img": 'https://file.jinglinshe.com/kittyhow/6-Speed.png'
        }
    ]
}


// 三端小程序说明页
export const descPage = {

    top: {
        img: "https://file.jinglinshe.com/kittyhow/橙黄色可爱卡通字体组合微信公众号封面 (4).jpg",
        desc: "三端知识变现小程序支持抖音、快手、微信，多端生态链接流量快速搭建知识变现链路闭环,满足不同生态需求。",
        tit: "你需要挂载",
        tit2: "哪个销售端口？"
    },



    con: {
        enrollDesc: {
            desc: {
                left: {
                    tit: '入驻扶持',
                    subtit: 'Support',
                    bg: "https://file.jinglinshe.com/kittyhow/未命名的设计.jpg"
                },
                list: [
                    {
                        tit: "运营玩法",
                        img: "https://file.jinglinshe.com/kittyhow/yxdc_logo.png",
                        desc: "提供三端同步指导和各种运营玩法指导帮助老师快速运营和售卖课程",
                    },
                    {
                        tit: "课程包装",
                        img: "https://file.jinglinshe.com/kittyhow/guanv_logo.png",
                        desc: "提供课程包装设计、账号诊断以及课程审核及风险评估",
                    },
                    {
                        tit: "流量扶持",
                        img: "https://file.jinglinshe.com/kittyhow/sy_logo.png",
                        desc: "入驻即可参与冲榜赢流量扶持活动，不定期申报参与官方流量扶持计划",
                    }
                ]
            },
            enrollDescTit: '知视猫 · 入驻流程',//知视猫 · 入驻流程
            flow: [
                {
                    tit: "1. 提交注册信息",
                    desc: "在抖音、快手或微信搜索知视猫小程序，用户中心申请入驻",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002256.png",
                },
                {
                    tit: "2. 创建课程",
                    desc: "在知视猫电脑端登录上传课程也支持手机端上传课程",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002224.png",
                },
                {
                    tit: "3. 课程审核",
                    desc: "课程通过知视猫一审和平台二次审核通过后可以上架",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002343.png",
                },
                {
                    tit: "4. 挂载能力授权",
                    desc: "课程通过审核后账号需要获取挂载能力的授权",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002413.png",
                },
                {
                    tit: "5. 挂载销售课程",
                    desc: "短视频和直播进行挂载课程开始知识变现之旅",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002458.png",
                }
            ]
        },
        contentList: [
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "多端流量",
                    "全链路闭环",
                    "多功能营销",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                ],
                descList: [
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ],
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ],
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                styles: 2,
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "一键同步",
                    "多端销售",
                    "高效运营",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                ],
                descList: [
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ],
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ],
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "多元形式",
                    "课程挂载",
                    "多功能营销",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                ],
                descList: [
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ],
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ],
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "支付担保",
                    "防录屏",
                    "版权保护",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                ],
                descList: [
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ],
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ],
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ]
                ]
            }
        ],
        shopTit: "合作伙伴"//标题
    }
}





// 多元营销页
export const marketing = {
    top: {
        img: "https://file.jinglinshe.com/kittyhow/多元营销.jpg",
        desc: "多渠道运营与营销方式提升流量转化，用户互动营销增加用户留存复购、提示客单",
        tit: "多元化营销体系",
    },
    nav: [
        {
            title: "分发营销"
        },
        {
            title: "平台推广计划"
        },
        {
            title: "抖音私域玩法"
        },
        {
            title: "自定义短信"
        },
        {
            title: "营销工具"
        }
    ],
    con: {
        contentList: [
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "任务设置",
                    "任务类型",
                    "自定义分发",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/分发营销.png",
                    "https://file.jinglinshe.com/kittyhow/分发营销.png",
                    "https://file.jinglinshe.com/kittyhow/分发营销.png",
                ],
                descList: [
                    [
                        "01",
                        "内外部资源分发营销",
                        "自定义设置推广奖励和分配机制，激活内外部社交资源进行推广",
                        "实现推广低成本传播式营销扩大成交与转化"
                    ],
                    [
                        "01",
                        "内外部资源分发营销",
                        "自定义设置推广奖励和分配机制，激活内外部社交资源进行推广",
                        "实现推广低成本传播式营销扩大成交与转化"
                    ],
                    [
                        "01",
                        "内外部资源分发营销",
                        "自定义设置推广奖励和分配机制，激活内外部社交资源进行推广",
                        "实现推广低成本传播式营销扩大成交与转化"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "内容查看",
                    "推广申请",
                    "推广数据",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/全链路内容管理.png",
                    "https://file.jinglinshe.com/kittyhow/全链路内容管理.png",
                    "https://file.jinglinshe.com/kittyhow/全链路内容管理.png",
                ],
                descList: [
                    [
                        "02",
                        "全链内容管理平台",
                        "查看内容推广信息、邀请推广、发布通告，全方位",
                        "推广服务内容管理需求",
                    ],
                    [
                        "02",
                        "全链内容管理平台",
                        "查看内容推广信息、邀请推广、发布通告，全方位",
                        "推广服务内容管理需求",
                    ],
                    [
                        "02",
                        "全链内容管理平台",
                        "查看内容推广信息、邀请推广、发布通告，全方位",
                        "推广服务内容管理需求",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                styles: 1,
                tit: [
                    "合作模式",
                    "自动生成",
                    "自动分账",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/多种合作模式.png",
                    "https://file.jinglinshe.com/kittyhow/多种合作模式.png",
                    "https://file.jinglinshe.com/kittyhow/多种合作模式.png",
                ],
                descList: [
                    [
                        "03",
                        "多元化营销工具",
                        "支持普通推广、定向佣金、发布通告、团长合作",
                        "四种合作模式，按照实际销售进行结算"
                    ],
                    [
                        "03",
                        "多元化营销工具",
                        "支持普通推广、定向佣金、发布通告、团长合作",
                        "四种合作模式，按照实际销售进行结算"
                    ],
                    [
                        "03",
                        "多元化营销工具",
                        "支持普通推广、定向佣金、发布通告、团长合作",
                        "四种合作模式，按照实际销售进行结算"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "小程序推广计划",
                    "课程推广",
                    "精选联盟",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/官方推广计划.png",
                    "https://file.jinglinshe.com/kittyhow/官方推广计划.png",
                    "https://file.jinglinshe.com/kittyhow/官方推广计划.png",
                ],
                descList: [
                    [
                        "",
                        "官方推广计划",
                        "抖音官方推出小程序推广计划，讲师可以申请将课程",
                        "同步到小程序推广计划，也可以同步到抖音橱窗通过 ",
                        "精选联盟进行达人推广合作",
                    ],
                    [
                        "",
                        "官方推广计划",
                        "抖音官方推出小程序推广计划，讲师可以申请将课程",
                        "同步到小程序推广计划，也可以同步到抖音橱窗通过 ",
                        "精选联盟进行达人推广合作",
                    ],
                    [
                        "",
                        "官方推广计划",
                        "抖音官方推出小程序推广计划，讲师可以申请将课程",
                        "同步到小程序推广计划，也可以同步到抖音橱窗通过 ",
                        "精选联盟进行达人推广合作",
                    ]
                ]
            },

            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "抖音粉丝群",
                    "私信组件",
                    "私域复购",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/私域转化.png",
                    "https://file.jinglinshe.com/kittyhow/私域转化.png",
                    "https://file.jinglinshe.com/kittyhow/私域转化.png",
                ],
                descList: [
                    [
                        "",
                        "抖音私域玩法",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化"
                    ],
                    [
                        "",
                        "抖音私域玩法",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化"
                    ],
                    [
                        "",
                        "抖音私域玩法",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "私域引流",
                    "营销互动"
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/自定义短信图.png",
                    "https://file.jinglinshe.com/kittyhow/自定义短信图.png",
                ],
                descList: [
                    [
                        "",
                        "自定义短信",
                        "支持自定义短信推送给购买课程的学员进行营销服务",
                        "提升用户互动与粘性，提供运营复购与教学指导服务"
                    ],
                    [
                        "",
                        "自定义短信",
                        "支持自定义短信推送给购买课程的学员进行营销服务",
                        "提升用户互动与粘性，提供运营复购与教学指导服务"
                    ],
                    [
                        "",
                        "自定义短信",
                        "支持自定义短信推送给购买课程的学员进行营销服务",
                        "提升用户互动与粘性，提供运营复购与教学指导服务"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "挂载组件",
                    "短视频挂载",
                    "直播挂载",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/营销工具挂载组件.png",
                    "https://file.jinglinshe.com/kittyhow/营销工具挂载组件.png",
                    "https://file.jinglinshe.com/kittyhow/营销工具挂载组件.png",
                ],
                descList: [
                    [
                        "",
                        "营销工具",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化",
                    ],
                    [
                        "",
                        "营销工具",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化",
                    ],
                    [
                        "",
                        "营销工具",
                        "讲师可以创建粉丝群和私信组件，用户下单后可以申请加入",
                        "粉丝群，实现私域运营、提高学员粘性从而提高复购转化",
                    ]
                ]
            }
        ],
        shopTit: "合作伙伴",//标题
        enrollDesc: {
            desc: {
                left: {
                    tit: '入驻扶持',
                    subtit: 'Support',
                    bg: "https://file.jinglinshe.com/kittyhow/未命名的设计.jpg"
                },
                list: [
                    {
                        tit: "运营玩法",
                        img: "https://file.jinglinshe.com/kittyhow/yxdc_logo.png",
                        desc: "提供三端同步指导和各种运营玩法指导帮助老师快速运营和售卖课程",
                    },
                    {
                        tit: "课程包装",
                        img: "https://file.jinglinshe.com/kittyhow/guanv_logo.png",
                        desc: "提供课程包装设计、账号诊断以及课程审核及风险评估",
                    },
                    {
                        tit: "流量扶持",
                        img: "https://file.jinglinshe.com/kittyhow/sy_logo.png",
                        desc: "入驻即可参与冲榜赢流量扶持活动，不定期申报参与官方流量扶持计划",
                    }
                ]
            },
            enrollDescTit: '知视猫 · 入驻流程',//知视猫 · 入驻流程
            flow: [
                {
                    tit: "1. 提交注册信息",
                    desc: "在抖音、快手或微信搜索知视猫小程序，用户中心申请入驻",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002256.png",
                },
                {
                    tit: "2. 创建课程",
                    desc: "在知视猫电脑端登录上传课程也支持手机端上传课程",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002224.png",
                },
                {
                    tit: "3. 课程审核",
                    desc: "课程通过知视猫一审和平台二次审核通过后可以上架",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002343.png",
                },
                {
                    tit: "4. 挂载能力授权",
                    desc: "课程通过审核后账号需要获取挂载能力的授权",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002413.png",
                },
                {
                    tit: "5. 挂载销售课程",
                    desc: "短视频和直播进行挂载课程开始知识变现之旅",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002458.png",
                }
            ]
        }
    }
}





// 更多服务
export const serveing = {
    enrollDescList: [
        {
            tit: "运营玩法",
            img: "https://file.jinglinshe.com/kittyhow/yxdc_logo.png",
            desc: "提供三端同步指导和各种运营玩法指导帮助老师快速运营和售卖课程",
        },
        {
            tit: "课程包装",
            img: "https://file.jinglinshe.com/kittyhow/guanv_logo.png",
            desc: "提供课程包装设计、账号诊断以及课程审核及风险评估",
        },
        {
            tit: "流量扶持",
            img: "https://file.jinglinshe.com/kittyhow/sy_logo.png",
            desc: "入驻即可参与冲榜赢流量扶持活动，不定期申报参与官方流量扶持计划",
        }
    ],
    con: {

        contentList: [
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "多端流量",
                    "全链路闭环",
                    "多功能营销",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                    "https://file.jinglinshe.com/kittyhow/3端挂载展示.jpg",
                ],
                descList: [
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ],
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ],
                    [
                        "过亿日活跃用户",
                        "公域流量助力内容曝光",
                        "打通抖音+快手+微信三端平台流量端口，获取平台生态流量"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                styles: 2,
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "一键同步",
                    "多端销售",
                    "高效运营",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                    "https://file.jinglinshe.com/kittyhow/3端小程序一键同步.png",
                ],
                descList: [
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ],
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ],
                    [
                        "",
                        "一键同步课程",
                        "只需一个后台，即可同时管理抖音+快手+微信三端课程",
                        "高效管理课程设置，减少运营成本",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "多元形式",
                    "课程挂载",
                    "多功能营销",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                    "https://file.jinglinshe.com/kittyhow/多元化营销.png",
                ],
                descList: [
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ],
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ],
                    [
                        "",
                        "多元化营销工具",
                        "丰富多样的营销工具提升转化效率，支持抖音粉丝群、微信群分享",
                        "助力公域流量转化与私域运营"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "支付担保",
                    "防录屏",
                    "版权保护",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                    "https://file.jinglinshe.com/kittyhow/安全保障.png",
                ],
                descList: [
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ],
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ],
                    [
                        "",
                        "支付担保安全保护",
                        "抖音、快手、微信平台支付担保，收益实时展示",
                        "用户只能在端内进行观看与学习，防录屏；版权保护"
                    ]
                ]
            }
        ],
        shopTit: "合作伙伴"//标题
    },
    top: {
        img: "https://file.jinglinshe.com/kittyhow/更多服务.jpg",
        desc: "提供知视猫多重服务，助力您知识变现",
        tit: "更多服务",
    },
    nav: [
        {
            title: "数据诊断"
        },
        {
            title: "包装设计"
        },
        {
            title: "担保交易"
        },
        {
            title: "版权保护"
        },
        {
            title: "专属客服"
        }
    ],
    con: {
        contentList: [
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "账号搭建诊断",
                    "内容课程诊断",
                    "营销售卖诊断",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/数据诊断.png",
                    "https://file.jinglinshe.com/kittyhow/数据诊断.png",
                    "https://file.jinglinshe.com/kittyhow/数据诊断.png",
                ],
                descList: [
                    [
                        "",
                        "数据驱动赋能营销策略",
                        "把握用户偏好、掌握用户行为轨迹、粉丝画像、流量互动",
                        "了解各项数据是为了更好的提升营销效果，快速定位问题"
                    ],
                    [
                        "",
                        "数据驱动赋能营销策略",
                        "把握用户偏好、掌握用户行为轨迹、粉丝画像、流量互动",
                        "了解各项数据是为了更好的提升营销效果，快速定位问题"
                    ],
                    [
                        "",
                        "数据驱动赋能营销策略",
                        "把握用户偏好、掌握用户行为轨迹、粉丝画像、流量互动",
                        "了解各项数据是为了更好的提升营销效果，快速定位问题"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "封面图设计",
                    "详情图设计",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/封面图设计.png",
                    "https://file.jinglinshe.com/kittyhow/封面图设计.png",
                    "https://file.jinglinshe.com/kittyhow/封面图设计.png",
                ],
                descList: [
                    [
                        "",
                        "课程包装设计",
                        "知视猫提供简易的课程模板外，也提供课程封面和",
                        "详情图设计助力知识博主优化课程",
                    ],
                    [
                        "",
                        "课程包装设计",
                        "知视猫提供简易的课程模板外，也提供课程封面和",
                        "详情图设计助力知识博主优化课程",
                    ],
                    [
                        "",
                        "课程包装设计",
                        "知视猫提供简易的课程模板外，也提供课程封面和",
                        "详情图设计助力知识博主优化课程",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                styles: 1,
                tit: [
                    "支付担保",
                    "平台交易",
                    "方便快捷",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/支付担保.png",
                    "https://file.jinglinshe.com/kittyhow/支付担保.png",
                    "https://file.jinglinshe.com/kittyhow/支付担保.png",
                ],
                descList: [
                    [
                        "",
                        "平台核销组件规避资金风险",
                        "担保交易系统接入，多重资金保障，让收益更安全",
                        "账户收益随时提现、提现流程简单便捷"
                    ],
                    [
                        "",
                        "平台核销组件规避资金风险",
                        "担保交易系统接入，多重资金保障，让收益更安全",
                        "账户收益随时提现、提现流程简单便捷"
                    ],
                    [
                        "",
                        "平台核销组件规避资金风险",
                        "担保交易系统接入，多重资金保障，让收益更安全",
                        "账户收益随时提现、提现流程简单便捷"
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "原创申明",
                    "版权保护",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/shopList/%E5%8E%9F%E5%88%9B%E5%A3%B0%E6%98%8E.png",
                    "https://file.jinglinshe.com/kittyhow/shopList/%E5%8E%9F%E5%88%9B%E5%A3%B0%E6%98%8E.png",
                    "https://file.jinglinshe.com/kittyhow/shopList/%E5%8E%9F%E5%88%9B%E5%A3%B0%E6%98%8E.png",
                ],
                descList: [
                    [
                        "",
                        "支持原创声明版权保护",
                        "上传课程需提交原创声明，当原创作品版权受侵权风险时，",
                        "联系知视猫第一时间取证，可实现对侵权作品下架操作， ",
                        "及时止损，可提供完整证据链协助侵权诉讼",
                    ],
                    [
                        "",
                        "支持原创声明版权保护",
                        "上传课程需提交原创声明，当原创作品版权受侵权风险时，",
                        "联系知视猫第一时间取证，可实现对侵权作品下架操作， ",
                        "及时止损，可提供完整证据链协助侵权诉讼",
                    ],
                    [
                        "",
                        "支持原创声明版权保护",
                        "上传课程需提交原创声明，当原创作品版权受侵权风险时，",
                        "联系知视猫第一时间取证，可实现对侵权作品下架操作， ",
                        "及时止损，可提供完整证据链协助侵权诉讼",
                    ]
                ]
            },

            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "课程审核",
                    "技术支持",
                    "运营答疑",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/专属客服服务.png",
                    "https://file.jinglinshe.com/kittyhow/专属客服服务.png",
                    "https://file.jinglinshe.com/kittyhow/专属客服服务.png",
                ],
                descList: [
                    [
                        "",
                        "专属客服服务",
                        "为课程老师提供技术支持、课程包装、销售指导的全链路",
                        "专属服务，7-12多对一服务"
                    ],
                    [
                        "",
                        "专属客服服务",
                        "为课程老师提供技术支持、课程包装、销售指导的全链路",
                        "专属服务，7-12多对一服务"
                    ],
                    [
                        "",
                        "专属客服服务",
                        "为课程老师提供技术支持、课程包装、销售指导的全链路",
                        "专属服务，7-12多对一服务"
                    ]
                ]
            }
        ]
    }
}











// 知视学苑页面
export const academy = {
    top: {
        img: "https://file.jinglinshe.com/kittyhow/知视学苑.jpg",
        desc: "基于知识内容创作与知识变现为核心，依托知视猫丰富的知识付费赛道经验+品牌IP推动，提供全场景内容营销服务",
        tit: "助力知识博主内容IP孵化",
    },
    fuhua: {
        title: '知视猫 · 孵化服务',
        list: [
            {
                tit: '内容生态',
                subTit: '提供各类泛知识教育内容的知识变现形态，涵盖付费课程、达人IP、矩阵搭建、营销等内容形态',
                bg: "https://file.jinglinshe.com/kittyhow/b1.jpg"
            },
            {
                tit: '互动营销',
                subTit: '提供多元化营销工具和链路，联动推广资源，全方位提升营销效果',
                bg: "https://file.jinglinshe.com/kittyhow/b2.png"
            },
            {
                tit: '全链路服务',
                subTit: '专业团队提供从策划、内容制作、课程包装、定制化营销等一站式服务',
                bg: "https://file.jinglinshe.com/kittyhow/b3.jpg"
            },
            {
                tit: '数据驱动',
                subTit: '深度洞察行业信息，全面解析营销数据实现营销策略与效果最大化',
                bg: "https://file.jinglinshe.com/kittyhow/b4.jpg"
            }
        ]
    },
    ugczhuanhua: [
        {
            title: '知视猫 · UGC转化',
            child: [
                {
                    bg: 'https://file.jinglinshe.com/kittyhow/UGC转化.png',
                    tit: '打造知识付费IP优选平台',
                    subt: [
                        '通过专业化的运营和培训服务来提升知识变现的效果最大化',
                        '满足知识内容创作行业场景的营销与成长需求。',
                    ],
                    tags: {
                        tit: '服务：',
                        list: [
                            '内容定位',
                            '营销运营',
                            '运营培训',
                        ]
                    }
                }
            ]
        },
        {
            title: '知视猫 · 持续为客户创造价值',
            child: [
                {
                    bg: 'https://file.jinglinshe.com/kittyhow/视觉.png',
                    tit: '视觉传达',
                    subt: [
                        '提供利用视觉符号来提高课程的质量传递专业的质量',
                        '满足专业知识付费场景的营销需求。',
                    ],
                    tags: {
                        tit: '服务：',
                        list: [
                            '课程设计',
                            '封面设计',
                            '详情图设计',
                            '活动页面设计',
                        ]
                    }
                },
                {
                    bg: 'https://file.jinglinshe.com/kittyhow/蓝白色眼睛矢量插画现代全国爱眼日节日宣传中文海报.png',
                    rightList: [
                        {
                            tags: [
                                "视光行业",
                                "门店引流",
                            ],
                            detailTit: '查看详情',
                            tit: '#少儿近视马叔',
                            bg: 'https://file.jinglinshe.com/kittyhow/蓝白色眼睛矢量插画现代全国爱眼日节日宣传中文海报.png'
                        },
                        {
                            tags: [
                                "AI绘画",
                                "AI模型搭建",
                            ],
                            detailTit: '查看详情',
                            tit: '#林SIR不画画',
                            bg: 'https://file.jinglinshe.com/kittyhow/蓝白色眼睛矢量插画现代全国爱眼日节日宣传中文海报.jpg'
                        },
                        {
                            tags: [
                                "英语场景教学",
                                "学英语",
                            ],
                            detailTit: '查看详情',
                            tit: '#跟JACK老师学英语',
                            bg: 'https://file.jinglinshe.com/kittyhow/蓝白色眼睛矢量插画现代全国爱眼日节日宣传中文海报 (1).jpg'
                        },
                        {
                            tags: [
                                "汽车维修",
                                "新能源",
                            ],
                            detailTit: '查看详情',
                            tit: '#孙老师能源汽车维修',
                            bg: 'https://file.jinglinshe.com/kittyhow/金白色汽车商务优惠券.jpg'
                        }
                    ],
                    tit: '内容案例',
                    subt: [
                        '围绕知识内容的属性、特定、价值，设计专属的内容',
                        '变现方式与内容定位，从不同角度切人特定塑造变现链路。',
                    ],
                    tags: {
                        tit: '服务：',
                        list: [
                            '变现工具',
                            '人群定位',
                            '课程设计',
                            '变现路径规划',
                        ]
                    }
                }
            ]
        }
    ],
    zhaoshangImg: "https://file.jinglinshe.com/kittyhow/招商项目.jpg"
    ,
    con: {
        shopTit: '合作伙伴'
    }
}


















// 讲师首页
export const shopJson = {
    top: {
        img: "https://file.jinglinshe.com/kittyhow/讲师后台.jpg",
        desc: "全方位功能体系帮助知识内容创造者更加快捷的搭建知识店铺，完善的功能组件提高运营效率",
        tit: "内容创作智能服务平台",
    },
    // 应用场景
    scenceCon: {
        title: '应用场景',
        img: 'https://file.jinglinshe.com/kittyhow/应用场景.png'
    },
    nav: [
        {
            title: "知识付费"
        },
        {
            title: "产品价值"
        },
        {
            title: "核心功能"
        },
        {
            title: "应用场景"
        },
        {
            title: "优质案例"
        }
    ],
    con: {
        contentList: [
            {
                btn: '立即体验',
                btnLink: 'https://kittyhow.com/login',
                styles: 1,
                choose: 0,//默认选中
                position: "right",
                tit: [
                    "多功能组件",
                    "操作简单",
                    "知识付费",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/全方位管理系统.png",
                    "https://file.jinglinshe.com/kittyhow/全方位管理系统.png",
                    "https://file.jinglinshe.com/kittyhow/全方位管理系统.png",
                ],
                descList: [
                    [
                        "",
                        "全方位管理系统",
                        "提供多功能组件帮助老师快手创建店铺、上传课程、追",
                        "踪审核状态、课程管理，多端同步、收益管理等功能附",
                        "能知识变现",
                    ],
                    [
                        "",
                        "全方位管理系统",
                        "提供多功能组件帮助老师快手创建店铺、上传课程、追",
                        "踪审核状态、课程管理，多端同步、收益管理等功能附",
                        "能知识变现",
                    ],
                    [
                        "",
                        "全方位管理系统",
                        "提供多功能组件帮助老师快手创建店铺、上传课程、追",
                        "踪审核状态、课程管理，多端同步、收益管理等功能附",
                        "能知识变现",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                styles: 1,
                choose: 0,//默认选中
                position: "left",
                tit: [
                    "产品价值",
                    "短视频/直播玩法",
                    "分润分账",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/赋能知识变现.png",
                    "https://file.jinglinshe.com/kittyhow/赋能知识变现.png",
                    "https://file.jinglinshe.com/kittyhow/赋能知识变现.png",
                ],
                descList: [
                    [
                        "",
                        "赋能知识变现",
                        "短视频平台已成流量最大来源蓝海，一键打造专属，知识店铺",
                        "打通多个短视频平台、提供各类营销工具和能力、分润分账",
                    ],
                    [
                        "",
                        "赋能知识变现",
                        "短视频平台已成流量最大来源蓝海，一键打造专属，知识店铺",
                        "打通多个短视频平台、提供各类营销工具和能力、分润分账",
                    ],
                    [
                        "",
                        "赋能知识变现",
                        "短视频平台已成流量最大来源蓝海，一键打造专属，知识店铺",
                        "打通多个短视频平台、提供各类营销工具和能力、分润分账",
                    ]
                ]
            },
            {
                btn: '立即体验',
                btnLink: '',
                choose: 0,//默认选中
                position: "left",
                styles: 1,
                tit: [
                    "课程管理",
                    "达人服务",
                    "营销中心",
                ],
                descImg: [
                    "https://file.jinglinshe.com/kittyhow/多样功能满足管理需求2.png",
                    "https://file.jinglinshe.com/kittyhow/多样功能满足管理需求2.png",
                    "https://file.jinglinshe.com/kittyhow/多样功能满足管理需求.png",
                ],
                tags: [
                    "店铺管理",
                    "内容管理",
                    "达人服务",
                    "收益管理",
                    "订单管理",
                    "素材库",
                    "营销中心",
                    "私域管理",
                ],//标签
                descList: [
                    [
                        "",
                        "多样功能满足管理需求",
                        "不断优化后台功能组件满足知识博主内容变现需求",
                    ],
                    [
                        "",
                        "多样功能满足管理需求",
                        "不断优化后台功能组件满足知识博主内容变现需求",
                    ],
                    [
                        "",
                        "多样功能满足管理需求",
                        "不断优化后台功能组件满足知识博主内容变现需求",
                    ]
                ]
            }
        ],
        shopTit: "优质案例",//标题
        enrollDesc: {
            desc: {
                left: {
                    tit: '入驻扶持',
                    subtit: 'Support',
                    bg: "https://file.jinglinshe.com/kittyhow/未命名的设计.jpg"
                },
                list: [
                    {
                        tit: "运营玩法",
                        img: "https://file.jinglinshe.com/kittyhow/yxdc_logo.png",
                        desc: "提供三端同步指导和各种运营玩法指导帮助老师快速运营和售卖课程",
                    },
                    {
                        tit: "课程包装",
                        img: "https://file.jinglinshe.com/kittyhow/guanv_logo.png",
                        desc: "提供课程包装设计、账号诊断以及课程审核及风险评估",
                    },
                    {
                        tit: "流量扶持",
                        img: "https://file.jinglinshe.com/kittyhow/sy_logo.png",
                        desc: "入驻即可参与冲榜赢流量扶持活动，不定期申报参与官方流量扶持计划",
                    }
                ]
            },
            flow: [
                {
                    tit: "1. 提交注册信息",
                    desc: "在抖音、快手或微信搜索知视猫小程序，用户中心申请入驻",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002256.png",
                },
                {
                    tit: "2. 创建课程",
                    desc: "在知视猫电脑端登录上传课程也支持手机端上传课程",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002224.png",
                },
                {
                    tit: "3. 课程审核",
                    desc: "课程通过知视猫一审和平台二次审核通过后可以上架",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002343.png",
                },
                {
                    tit: "4. 挂载能力授权",
                    desc: "课程通过审核后账号需要获取挂载能力的授权",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002413.png",
                },
                {
                    tit: "5. 挂载销售课程",
                    desc: "短视频和直播进行挂载课程开始知识变现之旅",
                    icon: "https://file.jinglinshe.com/kittyhow/微信截图_20231201002458.png",
                }
            ]
        }
    }
}

























// 短视频挂载合集页
export const dyLive = {
    shopInfo: {
        tit: "合作伙伴",
        subT: '越来越多的知识博主与知视猫一起成长',
        numList: [
            {
                num: "1",
                per: '亿+',
                subT: "累计覆盖用户"
            },
            {
                num: "20000",
                per: '+',
                subT: "知识内容创作者"
            },
            {
                num: "18",
                per: '亿+',
                subT: "话题播放量"
            }
        ]
    },
    top: {
        img: "https://file.jinglinshe.com/kittyhow/挂载能力.jpg",
        desc: "通过短视频和直播间可以展示挂载课程的购买路径快速引流用户进行成交",
        tit: "短视频和直播挂载",
    },
    // 导航位置
    navBar: {
        tit: '了解挂载能力',
        list: [
            {
                tit: '一站式营销',
                subT: '多种变现渠道解决流量问题提供一体化解决方案',
                icon: "https://file.jinglinshe.com/kittyhow/挂载1.png"
            },
            {
                tit: '最短购买链路',
                subT: '短视频/直播间可最短链路触达用户完成支付',
                icon: "https://file.jinglinshe.com/kittyhow/挂载2.png"
            },
            {
                tit: '海量用户群体',
                subT: '获取短视频平台海量流量公域推流满足营销目标',
                icon: "https://file.jinglinshe.com/kittyhow/未命名的设计 (5).png"
            },
            {
                tit: '服务体验',
                subT: '摆脱传统知识普及模式提升用户互动与深度社群粘性',
                icon: "https://file.jinglinshe.com/kittyhow/未命名的设计 (7).png"
            }
        ]
    },
    // idea方法论
    ideaFun: {
        index: 0,//当前选中
        tit: "知视猫 · IDEA方法论",
        list: [
            {
                tit: '洞察行业机会',
                sutT: "Insight",
                rCon: {
                    tit: 'Insight · 洞察行业机会',
                    subT: [

                        "基于短视频平台的发展",
                        "洞察泛知识行业的机会赛道、目标人群、",
                        "用户需求、内容趋势、课程定位、传播方式"
                    ]
                },
                lIcon: 'https://file.jinglinshe.com/kittyhow/洞察行业机会.jpg'
            },
            {
                tit: '定义课程策略',
                sutT: "Define",
                rCon: {
                    tit: 'Define · 定义课程策略',
                    subT: [

                        "基于短视频平台的发展",
                        "泛知识行业拥有机会赛道、目标人群",
                        "人群需求、内容趋势、课程口碑、互动传播"
                    ]
                },
                lIcon: 'https://file.jinglinshe.com/kittyhow/定义课程.jpg'
            },
            {
                tit: '拓展类目赛道',
                sutT: "Expand",
                rCon: {
                    tit: 'Expand · 拓展类目赛道',
                    subT: [

                        "精细化人群策略、",
                        "内容策略运营、通过运营组合、",
                        "直播话术和营销工具以及高效投放，抢占赛道"
                    ]
                },
                lIcon: 'https://file.jinglinshe.com/kittyhow/拓展行业赛道.jpg'
            },
            {
                tit: '缩短营销链路',
                sutT: "Advocate",
                rCon: {
                    tit: 'Advocate · 缩短营销链路',
                    subT: [

                        "沉淀内容、公域转私域、",
                        "直播间引导缩短课程成交链路，提交转化",
                        ""
                    ]
                },
                lIcon: 'https://file.jinglinshe.com/kittyhow/缩短营销链路.jpg'
            }
        ]
    },
    // 轮播图
    liveSwiperCon: {
        tit: '知视猫 · 短视频/直播灵感',
        list: [
            {
                tit: '人群破圈',
                subT: '开拓新赛道与新用户，突破教育营销瓶颈',
                detailCon: {
                    img: 'https://file.jinglinshe.com/kittyhow/浅绿青白色国风培训演示文稿 (1).jpg',
                    tit: '运动与解剖： 主打健身康复人群，实现人群扩圈',
                    subT: [
                        "Insight&Define：洞察热门健身项目，定位运动康复与健身技巧为切人点 ",
                        "Expand&Advocate：放大用户痛点与猎奇心理，重塑运动与健康方面的科普感知",
                    ],
                    detailText: '查看详情',
                    numList: [
                        {
                            num: '224',
                            percent: '%+',
                            subT: '赞粉比'
                        },
                        {
                            num: '64.66',
                            percent: '%+',
                            subT: '人群活跃度'
                        }
                    ]

                }
            },
            {
                detailCon: {
                    img: 'https://file.jinglinshe.com/kittyhow/浅绿青白色国风培训演示文稿 (1).jpg',
                    tit: '悉目眼镜： 借势知识变现风潮，实现线下精准引流',
                    subT: [
                        "Insight：洞察短视频平台“知识变现风潮”，定位少儿验光痛点现状 ",
                        "Define： 实体店经营管理+IP赋能+少儿验光赛道知识普及，重塑传统眼镜店破局关键",
                        "Expand&Advocate：直播互动专业知识普及、实例讲解，垂类流量放大影响力",
                    ],
                    detailText: '查看详情',
                    numList: [
                        {
                            num: '200',
                            percent: '%+',
                            subT: '搜索量提升'
                        },
                        {
                            num: '10',
                            percent: '倍',
                            subT: '实体销售增长'
                        },
                        {
                            num: 'NO.1',
                            percent: '',
                            subT: '行业类目转型'
                        }
                    ]

                },
                tit: '突破行业传统',
                subT: '打破传统行业困局，开拓行业新思路'
            },
            {
                detailCon: {
                    img: 'https://file.jinglinshe.com/kittyhow/浅绿青白色国风培训演示文稿 (1).jpg',
                    tit: '手工课程： 找准蓝海赛道，收徒带课',
                    subT: [
                        "Insight：传统手工类课程实现泛知识变现  ",
                        "Define： 根据类目和用户场景重新定义行业再创业",
                        "Expand&Advocate：实例讲解，技巧展示赋能生活技巧",
                    ],
                    detailText: '查看详情',
                    numList: [
                        {
                            num: '200',
                            percent: '%+',
                            subT: '直播量增长'
                        },
                        {
                            num: '10',
                            percent: '%',
                            subT: '品类增长'
                        }
                    ]

                },
                tit: '潜在用户获取',
                subT: '高效定位用户群体，找准课程营销方向'
            }
        ]


    },
    playCon: {
        current: 0,//当前选中的索引
        currentChild: 0,//当前选中的二级索引
        tit: '知视猫 · 多元化玩法',
        list: [
            {
                tit: "购买意向",
                subT: "Intention",
                childList: [
                    // 小程序首页截图
                    {
                        tit: '小程序',
                        desc: `提供抖音、快手、
                       微信知识变现应用
                       服务，承接多种变
                       现转化行为`,
                        img: 'https://file.jinglinshe.com/kittyhow/小程序2.jpg'
                    },
                    // 商品详情页录视频录屏
                    {
                        tit: '商品详情页',
                        desc: `快速搭建知识课程
                        售卖页面，丰富原生
                        组件与平台官方组件`,
                        img: 'https://file.jinglinshe.com/kittyhow/商品详情页.mp4'
                    },
                    {
                        // 店铺页面
                        tit: '店铺页面',
                        desc: `聚合课程内容，展示
                        讲师店铺信息，支持
                        加入粉丝群（抖音）`,
                        img: 'https://file.jinglinshe.com/kittyhow/讲师主页.jpg'
                    }
                ]
            },
            {
                tit: "用户触达",
                subT: "Impression",
                childList: [
                    {
                        tit: '短视频挂载',
                        desc: `提供短视频和挂载
                        课程工具，短视频触达用户点击`,
                        img: 'https://file.jinglinshe.com/kittyhow/短视频.mp4'
                    },
                    {
                         // 提供
                        tit: '直播挂载',
                        desc: `直播间引流用户点击课程卡片，
                        跳转到商品购买页面进行支付`,
                        img: 'https://file.jinglinshe.com/kittyhow/直播.mp4'
                    },
                    {
                         // 提供
                        tit: '私信分享',
                        desc: `私信课程到粉丝群和用户站内私
                        快速点击进行转化`,
                        img: 'https://file.jinglinshe.com/kittyhow/私信分享.mp4'
                    }
                ]
            },
            {
                tit: "互动兴趣",
                subT: "Interest",
                childList: [
                    {
                        tit: '个人主页',
                        desc: `提供主创和主页挂载和课程展示`,
                        img: 'https://file.jinglinshe.com/kittyhow/个人主页橱窗.mp4'
                    },
                    {
                        tit: '抖加投放',
                        desc: `挂载内容可以通过
                        抖加投放加热增加曝光度`,
                        img: 'https://file.jinglinshe.com/kittyhow/投放抖加.jpg'
                    },
                    // {
                    //     tit: '店铺页面',
                    //     desc: `聚合课程内容，展示
                    //     讲师店铺信息，支持
                    //     加入粉丝群（抖音）`,
                    //     img: 'https://file.jinglinshe.com/kittyhow/121312312.jpg'
                    // }
                ]
            },
            {
                tit: "营销玩法",
                subT: "Promotion",
                childList: [
                    {
                        tit: '优惠券',
                        desc: `创建优惠券、限时活动
                        提高用户购买率`,
                        img: 'https://file.jinglinshe.com/kittyhow/优惠券.mp4'
                    },
                    {
                        tit: '分销',
                        desc: `快速帮助分销达人搭建分销
                        渠道，实现分销、分账转化`,
                        img: 'https://file.jinglinshe.com/kittyhow/分销.mp4'
                    },
                    {
                        tit: '私域粉丝群',
                        desc: `用户下单后可以申请加入老师指定粉丝群，帮助老师后续私域转化`,
                        img: 'https://file.jinglinshe.com/kittyhow/粉丝群.jpg'
                    }
                ]
            }
        ]
    }







}






export const shopList = [
    {
        tit: '有张法',
        subTit: "（法律科普）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/1.png'
    },
    {
        tit: '盛世太极拳',
        subTit: "（传统武术）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/2.png'
    },
    {
        tit: '林SIR不画画',
        subTit: "（前沿技术）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/3.png'
    },
    {
        tit: '瑞姐学钩织',
        subTit: "（手工教学）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/4.png'
    },
    {
        tit: '尼翔讲书',
        subTit: "（家庭教育）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/5.png'
    },
    {
        tit: '惠心面食坊',
        subTit: "（面点烹饪）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/6.png'
    },
    {
        tit: '退休琴姨钢琴弹唱',
        subTit: "（音乐教学）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/7.png'
    },
    {
        tit: '绿豆god(无畏契约)',
        subTit: "（游戏攻略）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/8.png'
    },
    {
        tit: '新能源汽修孙老师',
        subTit: "（汽车维修）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/9.png'
    },
    {
        tit: '艾克心理',
        subTit: "（心理知识）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/10.png'
    },
    {
        tit: '老猫谈狗',
        subTit: "（宠物生活）",
        img: 'https://file.jinglinshe.com/kittyhow/shopList/11.png'
    }
]

// 关于我们页跳转相关
export const aboutUsJumpLink = [
    {
        id: 1,
        dictValue: '抖音',
        link: 'https://www.douyin.com/user/MS4wLjABAAAAzrbARhHae3WpJspTIn0SHNnTOhDzXCWSgXVtqUTk3jRxvfCi-APczjx0tJoUNiLT'
    },
    {
        id: 2,
        dictValue: '快手',
        link: 'https://www.kuaishou.com/profile/3xq3f8twtz7us76'
    },
    {
        id: 3,
        dictValue: '微信',
        link: 'https://mp.weixin.qq.com/s/jsdNv-LrPphPI7-JbNKE3Q'
    },
    {
        id: 4,
        dictValue: '知乎',
        link: 'https://www.zhihu.com/org/zhi-shi-mao-77'
    },
    {
        id: 5,
        dictValue: '邮件',
        link: 'mailto:eddiexie@dingtalk.com?subject=知视猫&body=联系'
    }
]
// 首页知视猫 · 运营服务成功案例
export const successList = [
    {
        tag: '案例故事',
        images:'https://file.jinglinshe.com/kittyhow/%E5%9B%BE%E7%89%87%2023@1x.png',
        question: '行业难做！其实是你的思维桎梏，传统眼镜行业如何做到转型和把握新的机遇？'
    },
    {
        tag: '90后创业',
        images:'https://file.jinglinshe.com/kittyhow/%E5%9B%BE%E7%89%87%2026@1x.png',
        question: 'AI 行业从业者如何做出爆款课程？'
    },
    {
        tag: '课程分享',
        images:'https://file.jinglinshe.com/kittyhow/%E5%9B%BE%E7%89%87%2025@1x.png',
        question: '唱歌不难、别怕张嘴，跟着小桐老师零基础学习流行演唱歌曲的各项技巧'
    }
]