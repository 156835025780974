<template>
    <headerTop @showModal="showModal">
    </headerTop>
    <router-view :form="form" @userEnroll="userEnroll" @showAccountModal="showAccountModal" @showWarnModal="showWarnModal" @showModal="showModal"></router-view>
    <service></service>
    <!-- <enrollPage @closeModal="data.enrollModal=false" :enrollModal="data.enrollModal"></enrollPage> -->
    <footerBar></footerBar>
    <!-- 入驻弹框 -->
    <el-dialog top="100px" :show-close="false" :modal="false" class="enroll" style="box-shadow: 0px 2px 6px 0px rgba(#c0bebe, 0.61);" v-model="data.enrollModal" width="480px">
            <el-icon class="pointer" @click="data.enrollModal=false" size="24" color="#101010">
                <Close />
            </el-icon>
            <div class="enrollForm">
                <div class="top text22">申请入驻知视猫卖课</div>
                <div class="subTop text14">我们的商务顾问将第一时间为您服务</div>
                <el-form class="form" label-position="top" :model="form" label-width="auto">
                    <el-form-item label="姓名">
                        <el-input maxlength="16" v-model="form.name" />
                    </el-form-item>
                    <el-form-item style="position:relative">
                        <div slot="label">手机号 <span class="text14">（将作为后台唯一登录账号，不可修改）</span> </div>
                        <el-input @input="changeMobile" maxlength="11" v-model.number="form.id" />
                        <span class="code text13 pointer" @click="sendMsgCode">{{verificate.tit}}</span>
                    </el-form-item>
                    <el-form-item label="验证码">
                        <el-input maxlength="8" v-model="form.code" />
                    </el-form-item>
                    <el-form-item label="课程类型">
                        <el-input placeholder-style="color:#9CA0AB" maxlength="32" v-model="form.goodType"  placeholder="请输入课程相关类型，如音乐课程、钩织课程"/>
                    </el-form-item>
                    <div class="submit pointer" @click="userEnroll(false)">提交申请</div>
                </el-form>
            </div>
            <div class="submitE">
                <div class="warn text13 flex_row_center flex-center">
                    <span>学员看课或购买课程请点击 </span>
                    <span class="pointer" @click="webStudy">学员看课 >></span>
                </div>
            </div>
        </el-dialog>
    <!-- 联系客服页 -->
    <el-dialog top="100px" :show-close="false" :modal="false" class="enroll" style="box-shadow: 0px 2px 6px 0px rgba(#c0bebe, 0.61);" v-model="data.accountModal" width="480px">
            <el-icon class="pointer" @click="data.accountModal=false" size="24" color="#101010">
                <Close />
            </el-icon>
            <div class="enrollForm agent">
                <div class="top text22">账号开通中</div>
                <div>
                    <div class="flex  flex_between step">
                        <div>
                            <img src="https://file.jinglinshe.com/kittyhow/antOutline-check-circle%403x.png" />
                            <span class="text14">输入信息</span>
                        </div>
                        <div class="line"></div>
                        <div>
                            <img src="https://file.jinglinshe.com/kittyhow/semiDesign-semi-icons-tick_circle%403x.png" />
                            <span class="text14">开通账号</span>
                        </div>
                    </div>
                    <div class="agentUser">
                        <span class="text16">- 添加您的专属官方工作人员 - </span>
                        <div class="userInfo flex-center flex_row_center">
                            <img src="https://file.jinglinshe.com/kittyhow/ercode-logo.png" />
                            <span>手机号：{{authority.wechatMobile}} </span>
                        </div>
                        <img :src="authority.wechatQaCode" />
                    </div>
                </div>
            </div>
        </el-dialog>
</template>
<script setup>
    import service from "@/components/service.vue"
    import headerTop from '@/components/header.vue';
    import footerBar from '@/components/footerbar.vue';
    import enrollPage from '@/components/enroll.vue';
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    import {
        timeCountDown
    } from '@/utils/index';
    import {
        ElMessage
    }
    from 'element-plus'
    const data = reactive({
        accountModal: false, //销售弹框
        enrollModal: false, //入驻弹框
    });
    const showModal = () => {
        data.enrollModal = true;
    }
    const showAccountModal = () => {
        data.accountModal = true;
    };
    // 获取默认销售人员
    const getAuthority = async() => {
        try {
            const response = await $axios.get(`/zsm/api/enroll/getAuthority`);
            for (let i in authority) {
                authority[i] = response.data.data[i]
            };
        } catch (error) {
            console.error(error)
        }
    };
    const IsPhone = function () {
    //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
    var info = navigator.userAgent;
    //通过正则表达式的test方法判断是否包含“Mobile”字符串
    var isPhone = /mobile/i.test(info);
    //如果包含“Mobile”（是手机设备）则返回true
    return isPhone;
};
    /**
     * 修改手机号
     * @param {String} e 输入内容
     */
    const changeMobile = (e) => {
        form.mobile = e.replace(/[^0-9-]+/g, '$1');
    };
    let form = reactive({
        platformDict:'pc',//平台
        name: "", //姓名
        goodType: "", //课程类型
        mobile: "", //手机号
        code: "", //验证码
    });
    const formWarn = reactive({
        name: "", //姓名
        mobile: "", //手机号
        code: "", //验证码
    });
    const verificate = reactive({
        flag: true, //状态，是否可以发送短信
        tit: "获取验证码", //描述
    });
    // 销售人员信息
    const authority = reactive({
        wechatMobile: "", //账号
        wechatQaCode: "", //二维码
    });
    // 电脑端学习
    const webStudy = async() => {
        window.open('https://kittyhow.com/study', '_black')
    };
    /**
     * 数据重置
     */
    const resetForm = (e) => {
        for (let i in form) {
            form[i] = ''
        }
    };
    // 讲师入驻
    const userEnroll = async(options) => {
        if (options) {
            form = options;
        };
        formWarn.name = form.name.length < 1 ? '请输入姓名！' : '';
        formWarn.mobile = form.mobile.length != 11 ? '请输入正确的手机号！' : '';
        formWarn.code = form.code == '' ? '请输入验证码！' : '';
        if (form.name != '' && form.mobile != '' && form.code != '') {
            const response = await $axios.post(`/zsm/api/enroll/createOtherEnrollForm`, form);
            if (response.data.result == 'true') {
                data.enrollModal = false;
                data.accountModal = true;
                resetForm();
            } else {
                ElMessage({
                    customClass: 'ele',
                    message: response.data.message,
                    type: 'error',
                })
            }
        } else {
            for (let i in formWarn) {
                if (formWarn[i] != '') {
                    ElMessage({
                        customClass: 'ele',
                        message: formWarn[i],
                        type: 'error',
                    })
                    return false;
                }
            }
        }
    };
    // 获取验证码
    const sendMsgCode = async() => {
        if (form.mobile.length == 11) {
            const response = await $axios.post(`/zsm/api/sms/sendMsg?mobile=${form.mobile}&msgDict=zsm.sms.templateCode.enroll`);
            if (response.data.result == 'true') {
                if (verificate.flag == true) {
                    timeCountDown(60, (res, status) => {
                        if (status) {
                            verificate.flag = status;
                        }
                        verificate.tit = res
                    });
                }
                ElMessage({
                    customClass: 'ele',
                    message: '发送成功！',
                    type: 'success'
                })
            }
        } else {
            ElMessage({
                customClass: 'ele',
                message: '请输入正确的手机号',
                type: 'error'
            })
        }
    };
    onMounted(() => {
        if(IsPhone()){
              window.location.href="https://kittyhow.com/zsmweb"
        }
        getAuthority();
        (function(m, ei, q, i, a, j, s) {
            m[i] = m[i] || function() {
                (m[i].a = m[i].a || []).push(arguments)
            };
            j = ei.createElement(q),
                s = ei.getElementsByTagName(q)[0];
            j.async = true;
            j.charset = 'UTF-8';
            j.src = 'https://static.meiqia.com/dist/meiqia.js';
            s.parentNode.insertBefore(j, s);
        })(window, document, 'script', '_MEIQIA');
        _MEIQIA('entId', 'f6202e7c1f44c964e3edfbdac7315ae4');
        // 在这里开启无按钮模式（常规情况下，需要紧跟在美洽嵌入代码之后）
        _MEIQIA('withoutBtn');
    })
</script>
<style lang="scss">
    @import './styles/reset.scss';
    @import './styles/enroll.scss';
    .ele {
        z-index: 9999!important;
    }
</style>