import axios from 'axios';
import BASE_URL from './BASE_URL';
export const $axios = axios.create({
  timeout: 5000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});

// request interceptor
$axios.interceptors.request.use(
  config => {
    if(!config.baseURL||config.baseURL.indexOf('http')==-1){
      config.baseURL = BASE_URL.ZSM_BASE_API
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)