<template>
  <div>
    <div class="container" style="position: relative">
      <div class="video_bg">
        <!-- <video cover="" muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="https://file.jinglinshe.com/kittyhow/%E7%9F%A5%E8%A7%86%E7%8C%AB%E5%AE%98%E7%BD%91%E6%96%B0%E7%89%88%E4%B8%BB%E9%A1%B53.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video> -->
        <!-- <video muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="https://file.jinglinshe.com/kittyhow/%E7%9F%A5%E8%A7%86%E7%8C%AB%E5%AE%98%E7%BD%91%E6%96%B0%E7%89%88%E4%B8%BB%E9%A1%B5.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video> -->
        <!-- <video muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="https://file.jinglinshe.com/kittyhow/%E7%9F%A5%E8%A7%86%E7%8C%AB%E5%AE%98%E7%BD%91%E6%96%B0%E7%89%88%E4%B8%BB%E9%A1%B54.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video> -->
        <!-- <video muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="https://file.jinglinshe.com/kittyhow/%E7%9F%A5%E8%A7%86%E7%8C%AB%E5%AE%98%E7%BD%91%E6%96%B0%E7%89%88%E4%B8%BB%E9%A1%B55.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video> -->
        <video muted="" data-autoplay="" loop="loop" autoplay="autoplay" src="@/assets/知视猫官网新版主页.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video>
      </div>
      <main>
        <div class="content_block block_1">
          <div style="margin-bottom: 50px">
            <span class="main_font" style="margin-right: 36px">知识付费卖课</span>
            <span class="main_font">只需</span>
            <span class="main_font" style="color: #ff3355">知视猫</span>
          </div>
          <div class="logo_list">
            <img src="@/assets/index/douyin.png">
            <div class="doudian"><img src="@/assets/index/doudian.png"></div>
            <img src="@/assets/index/kuaishou.png">
            <div class="shipinhao"><img src="@/assets/index/shipinhao.png"></div>
            <div class="weixin"><img src="@/assets/index/weixin.svg"></div>
          </div>
          <div class="tit_list" style="display: flex ">
            <div class="block_1_panel" style="background-color: rgba(248, 254, 255, 1);">
              <div class="title">知识变现</div>
              <div class="text">
                知识博主或教育机构都可以入驻 通过课程在短视频平台进行变现 解决知识变现难题。
              </div>
              <ul>
                <li>·&nbsp;&nbsp;知识付费</li>
                <li>·&nbsp;&nbsp;一次录制反复变现</li>
                <li>·&nbsp;&nbsp; 线上课程成交</li>
              </ul>
              <div class="corner_img" style="right:11px">
                <img src="@/assets/index/知识变现.png">
              </div>
            </div>
            <div class="block_1_panel" style="background-color: rgba(255, 248, 251, 1);">
              <div class="title">场景需求</div>
              <div class="text">
                知识内容创作者可以快速搭建 知识店铺通过小程序进行短视频 直播挂载课程。
              </div>
              <ul>
                <li>·&nbsp;&nbsp;一键开通店铺</li>
                <li>·&nbsp;&nbsp;多场景销售</li>
                <li>·&nbsp;&nbsp;多功能挂载</li>
              </ul>
              <div class="corner_img" style="right:6px">
                <img src="@/assets/index/场景需求.png">
              </div>
            </div>
            <div class="block_1_panel" style="background-color: rgba(248, 248, 255, 1);">
              <div class="title">流量市场</div>
              <div class="text">
                以主流短视频平台为生态核心进行 承载，为泛知识内容创作者提供 庞大的用户群体和强大的流量优势
              </div>
              <ul>
                <li>·&nbsp;&nbsp;公域/私域流量</li>
                <li>·&nbsp;&nbsp;粉丝聚合</li>
                <li>·&nbsp;&nbsp;平台流量推送</li>
              </ul>
              <div class="corner_img" style="right:20px">
                <img src="@/assets/index/流量市场.png">
              </div>
            </div>
            <div class="block_1_panel" style="
             
                        background-color: rgba(255, 250, 247, 1);
                        margin-right: 0;
             
                       ">
              <div class="title">最短链路</div>
              <div class="text">
                用户能够直接通过短视频内容或 直播间直接完成课程支付，让课程 营销更加的简短、精准。
              </div>
              <ul>
                <li>·&nbsp;&nbsp;购买偏好及链路短</li>
                <li>·&nbsp;&nbsp;引流转化</li>
                <li>·&nbsp;&nbsp; 营销行为快捷</li>
              </ul>
              <div class="corner_img" style="right:15px">
                <img src="@/assets/index/最短链路.png">
              </div>
            </div>
          </div>
        </div>
        <div class="content_block block_2 ">
          <div class="main_title">
            <div class="main_font">知视猫 · 挂载能力</div>
          </div>
          <div class="feature_list">
            <div class="main_bg">
              <div class="text16" style="margin-bottom: 9px">
                产品核心功能
              </div>
              <div class="text16">Functions</div>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/dysm_logo%20%281%29.png">
              <div class="text16" style="margin-bottom: 20px">
                抖音泛知识生态
              </div>
              <ul>
                <li>拥有着庞大的用户群体和强大的流量优势</li>
                <li>抖音的月活跃用户已经超过了8亿</li>
                <li>可以覆盖到各个年龄段和职业领域的人群</li>
              </ul>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/未命名的设计 (3).png">
              <div class="text16" style="margin-bottom: 20px">
                快手泛知识生态
              </div>
              <ul>
                <li>作为国民度高的短视频平台让创作者轻松创业</li>
                <li>公域流量池巨大蓝海机会</li>
                <li>市场逐年增长，内容变现入口多样化</li>
              </ul>
            </div>
            <div class="feature_pannel">
              <img style="width:24px;height:24px;margin:3px auto 6px auto" src="https://file.jinglinshe.com/kittyhow/微信泛知识生态.png">
              <div class="text16" style="margin-bottom: 20px">
                微信泛知识生态
              </div>
              <ul>
                <li>社交流量私域王者高地</li>
                <li>流量裂变性强</li>
                <li>视频号让微信生态公域私域皆得</li>
              </ul>
            </div>
          </div>
          <div class="slide_list">
            <div class="slide_pannel " :class="data.chooseGuazai==1?'slide_big':''" @mouseover="handleHoverGuazai(1)">
              <div class="bg" style="
                         background-image: url(https://file.jinglinshe.com/kittyhow/挂载展示.png);
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4 class="tit">内容创作者/机构达人，挂载后是怎么样的表现形式？</h4>
                <p>提供主流短视频平台课程挂载工具，助力达人一键挂载课程到短视频/直播间</p>
                <i class="slide_arrow"></i>
                <button class="button-reset" @click="$emit('showModal')">我要体验</button>
              </div>
            </div>
            <div class="slide_pannel" :class="data.chooseGuazai==2?'slide_big':''" @mouseover="handleHoverGuazai(2)">
              <div class="bg" style="
                         background-image: url(https://file.jinglinshe.com/kittyhow/挂载展示2.png);
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4>销售链路中怎么样才能提高课程商品的转化？</h4>
                <p>提供多种营销工具帮助提高课程销售，提升用户互动率，购买率和转化点击率。</p>
                <i class="slide_arrow"></i>
                <button class="button-reset" @click="$emit('showModal')">我要体验</button>
              </div>
            </div>
            <div class="slide_pannel" :class="data.chooseGuazai==3?'slide_big':''" @mouseover="handleHoverGuazai(3)">
              <div class="bg" style="
                         background-image: url(https://file.jinglinshe.com/kittyhow/学习3.png);
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4>用户购买后需要到哪里进行课程学习？</h4>
                <p>用户购买课程后可以通过手机移动端或者电脑端进行课程学习，同时也支持跨平台购买学习</p>
                <i class="slide_arrow"></i>
                <button class="button-reset" @click="$emit('showModal')">我要体验</button>
              </div>
            </div>
          </div>
        </div>
        <div class="content_block block_3">
          <div class="main_title">
            <div class="main_font">知视猫 · 营销管理</div>
          </div>
          <div class="feature_list">
            <div class="main_bg">
              <div class="text16" style="margin-bottom:9px">
                产品核心功能
              </div>
              <div class="text16">Functions</div>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/daren_log2o.png">
              <div class="text16" style="margin-bottom: 26px">
                达人管理
              </div>
              <div style="display: flex;justify-content:center">
                <ul style="margin-right: 40px">
                  <li>定向达人分销</li>
                  <li style="margin-bottom: 0">销售数据监控</li>
                </ul>
                <ul>
                  <li>挂载权限授权</li>
                  <li style="margin-bottom: 0">达人通告发布</li>
                </ul>
              </div>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/gua2nv_logo.png">
              <div class="text16" style="margin-bottom: 26px">
                订单管理
              </div>
              <div style="display: flex;justify-content:center">
                <ul>
                  <li>订单数据查看，对应订单跟进标签</li>
                  <li>课程学习比例追踪，售后二次挽回</li>
                </ul>
              </div>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/drdh_logo.png">
              <div class="text16" style="margin-bottom: 26px">
                私域转化
              </div>
              <div style="display: flex;justify-content:center">
                <ul>
                  <li>抖音粉丝群、抖音私信组件</li>
                  <li>自定义短信推送，引流私域转化</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="slide_list">
            <div class="slide_pannel " :class="data.chooseMarket==1?'slide_big':''" @mouseover="handleHoverMarket(1)">
              <div class="bg" style="
             
                         background-image: url(https://file.jinglinshe.com/kittyhow/销售1.png);
             
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4 class="tit">课程售卖场景下有哪些营销玩法可以助力精准营销与转化？</h4>
                <p>多种抢购、限时折扣、优惠券等大量花式营销工具，不断强化用户购买欲望，增加成单</p>
                <i class="slide_arrow"></i>
                <button @click="$emit('showModal')" class="button-reset">我要体验</button>
              </div>
            </div>
            <div class="slide_pannel" :class="data.chooseMarket==2?'slide_big':''" @mouseover="handleHoverMarket(2)">
              <div class="bg" style="
                         background-image: url(https://file.jinglinshe.com/kittyhow/官方推广计划.png);
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4>课程需要分销裂变怎么样进行优化和使用？</h4>
                <p>课程可以同步到多个分销平台，如小程序推广计划、抖店精选联盟、视频号选品中心等渠道来增加课程裂变</p>
                <i class="slide_arrow"></i>
                <button @click="$emit('showModal')" class="button-reset">我要体验</button>
              </div>
            </div>
            <div class="slide_pannel" :class="data.chooseMarket==3?'slide_big':''" @mouseover="handleHoverMarket(3)">
              <div class="bg" style="
                         background-image: url(https://file.jinglinshe.com/kittyhow/分销.png);
                        "></div>
              <div class="content">
                <i class="slide_quotation"></i>
                <h4>课程除了上架知视猫小程序还有哪些渠道帮助推广？</h4>
                <p>支持选择单独内容课程单独分销，也可在分销集合页同时分销多个内容课程，参与课程推广计划、精选联盟</p>
                <i class="slide_arrow"></i>
                <button @click="$emit('showModal')" class="button-reset">我要体验</button>
              </div>
            </div>
          </div>
        </div>
        <div class="content_block block_4">
          <div class="main_title">
            <div class="main_font">知视猫 · 运营服务</div>
          </div>
          <div class="feature_list">
            <div class="main_bg">
              <div class="text16" style="margin-bottom: 9px">
                产品核心功能
              </div>
              <div class="text16">Functions</div>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/zbdyy_logo.png">
              <div class="text16" style="margin-bottom: 20px">
                专属客服服务
              </div>
              <ul>
                <li>拥有着庞大的用户群体和强大的流量优势</li>
                <li>抖音的月活跃用户已经超过了8亿</li>
                <li>可以覆盖到各个年龄段和职业领域的人群</li>
              </ul>
              <!-- <div class="text13" style="line-height: 20px">
                        为课程老师提供技术支持到课程包装、销售的全链路专属服务，7-12多对一服务
                      </div> -->
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/zbpp_logo.png">
              <div class="text16" style="margin-bottom: 20px">
                账号孵化
              </div>
              <!-- <div class="text13" style="line-height: 20px">
                      账号申请到起号开播的全流程培训直播主播培训、短视频制作培训, 课程包装与录制培训
                    </div> -->
              <ul>
                <li>作为国民度高的短视频平台让创作者轻松创业</li>
                <li>公域流量池巨大蓝海机会</li>
                <li>市场逐年增长，内容变现入口多样化</li>
              </ul>
            </div>
            <div class="feature_pannel">
              <img src="https://file.jinglinshe.com/kittyhow/daren_log2o.png ">
              <!-- <div class="text16" style="margin-bottom: 20px">
                      达人带课服务
                    </div>
                    <div class="text13" style="line-height: 20px">
                      提供老师/培训机构与带货达人矩阵之间的人货 撮合匹配服务，打通短视频/直播销售通路
                    </div> -->
              <div class="text16" style="margin-bottom: 20px">
                直播间陪跑服务
              </div>
              <ul>
                <li>社交流量私域王者高地</li>
                <li>流量裂变性强</li>
                <li>视频号让微信生态公域私域皆得</li>
              </ul>
            </div>
          </div>
          <div class="case_list">
            <div class="text18" style="font-family: SourceHanSansSC-medium;margin: 26px 0 12px 0">
              成功案例
              <span style="font-family: SourceHanSansSC-regular; margin-left: 13px">Case</span>
            </div>
            <div class="logo_list2 ">
              <div @click="journalism(item.id)" class="item pointer" v-for="item of data.successList">
                <div class="logo_left">
                  <img :src="item.images" />
                </div>
                <div class="logo_right">
                  <div class="tags">{{item.tag}}</div>
                  <div>{{item.question}}</div>
                </div>
              </div>
              <!-- <div class="item">
                      <div class="logo_left">
                        <img src="https://file.jinglinshe.com/kittyhow/图片 23@1x.png" />
                      </div>
                      <div class="logo_right">
                        <div class="tags">案例故事</div>
                        <div>行业难做！其实是你的思维桎梏，传统眼镜行业如何做到转型和把握新的机遇？</div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="logo_left">
                        <img src="https://file.jinglinshe.com/kittyhow/图片 26@1x.png" />
                      </div>
                      <div class="logo_right">
                        <div class="tags">90后创业</div>
                        <div>AI 行业从业者如何做出爆款课程？</div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="logo_left">
                        <img src="https://file.jinglinshe.com/kittyhow/图片 25@1x.png" />
                      </div>
                      <div class="logo_right">
                        <div class="tags">课程分享</div>
                        <div>唱歌不难、别怕张嘴，跟着小桐老师零基础学习流行演唱歌曲的各项技巧</div>
                      </div>
                    </div> -->
            </div>
          </div>
        </div>
        <div class="content_block block_5">
          <div class="main_title" style="margin-bottom: 56px">
            <div class="main_font">知视猫 · 行业开放类目</div>
          </div>
          <div class="result_list">
            <div class="result_item">
              <div class="title text16">少儿成长</div>
              <div class="ul_list">
                <ul>
                  <li>· 家长教育</li>
                  <li>· 儿童兴趣</li>
                  <li>· 少儿编程</li>
                </ul>
              </div>
            </div>
            <div class="result_item">
              <div class="title text16">语言培训</div>
              <div class="ul_list">
                <ul>
                  <li>· 英语</li>
                  <li>· 汉语学习</li>
                  <li>· 日语</li>
                  <li>· 德语</li>
                  <li>· 韩语</li>
                  <li>· 俄语</li>
                </ul>
                <div style="padding-left:6px">......</div>
              </div>
            </div>
            <div class="result_item">
              <div class="title text16">生活兴趣</div>
              <div class="ul_list">
                <ul>
                  <li>· 音乐</li>
                  <li>· 美术</li>
                  <li>· 舞蹈</li>
                  <li>· 健身健美</li>
                  <li>· 法律科普</li>
                  <li>· 主持表演</li>
                  <li>· 手工艺</li>
                </ul>
                <div style="padding-left:6px">......</div>
              </div>
            </div>
            <div class="result_item">
              <div class="title text16">成人学历</div>
              <div class="ul_list">
                <ul>
                  <li>· 自学考试培训</li>
                  <li>· 研究生辅导</li>
                  <li>· 司法考试</li>
                  <li>· IT职业/计算机考试</li>
                  <li>· 其他职业考试</li>
                </ul>
                <div style="padding-left:6px">......</div>
              </div>
            </div>
            <div class="result_item">
              <div class="title text16">职业资格</div>
              <div class="ul_list">
                <ul>
                  <li>· 教师资格证考试</li>
                  <li>· 编制/公务员考试</li>
                  <li>· 语言师资培训</li>
                  <li>· 财会金融考试</li>
                  <li>· 家庭教育指导师培训</li>
                  <li>· 医学资格认证</li>
                  <li>· 建筑地产类培训</li>
                </ul>
                <div style="padding-left:6px">......</div>
              </div>
            </div>
            <div class="result_item">
              <div class="title text16">技能培训</div>
              <div class="ul_list">
                <ul>
                  <li>· 职业发展</li>
                  <li>· 房地产培训</li>
                  <li>· 企业管理</li>
                  <li>· 设计创作</li>
                  <li>· 汽修养护</li>
                  <li>· 消防与安全</li>
                  <li>· 烹饪餐饮培训</li>
                </ul>
                <div style="padding-left:6px">......</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_block block_6">
          <div class="main_font">
            知视猫 · 行业研究院
          </div>
          <div id="reportList" class="report_list">
            <!-- <router-link target="div" :to="'/journalism?id='+item.id" class="report_cell"  v-for="item of data.qaListHangye"> -->
            <div @click="journalism(item.id)" class="report_cell pointer" v-for="item of data.qaListHangye">
              <div class="report_img">
                <img :src="item.images" alt="">
              </div>
              <div class="report_title">{{item.question}}</div>
              <div class="report_content" v-html="item.answer"></div>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
        <div class="sec2">
          <section>
            <h2>看见知识、创造价值</h2>
            <div class="flex_between team-list">
              <li class="team" v-for="item of data.shopList">
                <img alt="知视猫" :src="item.img">
                <div>
                  <div class="text14">{{item.tit}}</div>
                  <span class="text12">{{item.subTit}}</span>
                </div>
              </li>
            </div>
          </section>
          <div class="use_btn" @click="$emit('showModal')">立即加入</div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
  import {
    onMounted,
    onUnmounted,
    reactive,
    ref
  } from 'vue';
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  import {
    contextRegExp2
  } from "@/utils/index"
  import {
    useRouter
  } from 'vue-router';
  import {
    shopList,
    successList
  } from "@/utils/json"
  const data = reactive({
    chooseGuazai: 1, //挂载动画处理
    chooseMarket: 1, //营销动画处理
    successList, //成功案例
    qaListHangye: [], // 知视猫 · 行业研究院 列表
    shopList // 合作伙伴
  });
  // 知视猫 · 挂载能力 滑入事件监听
  function handleHoverGuazai(index) {
    data.chooseGuazai = index;
  }
  // 知视猫 · 营销管理 滑入事件监听
  function handleHoverMarket(index) {
    data.chooseMarket = index;
  }
  // 根据分类获取帮助中心详情
  const qaListData = async() => {
    try {
      const response = await $axios.get('/zsm/api/qa/listData?qaDict=1001');
      response.data.list.map((item) => {
        item.answer = contextRegExp2(item.answer)
      })
      data.qaListHangye = response.data.list.slice(0, 4);
      let successList = response.data.list.slice(11, 14);
      data.successList.map((item, index) => {
        item.images = successList[index].images;
        item.id = successList[index].id;
        item.question = successList[index].question;
      })
    } catch (error) {
      console.error('请求失败:', error)
    }
  }
  // 路由
  const router = useRouter();
  // 跳转到问题详情页
  const journalism = (id) => {
    router.push({
      path: '/journalism',
      query: {
        id
      }
    })
  }
  onMounted(async() => {
    qaListData()
  })
  onUnmounted(() => {})
</script>

<style lang="scss" scoped>
  @import '@/styles/head.scss';
  @import '@/styles/new_index.scss';
</style>