// 格式化html文件
export function contextRegExp2(mixContent) {
  if (mixContent) {
    var re2 = new RegExp('＞', 'g')
    var re1 = new RegExp('＜', 'g')
    var re4 = new RegExp('＂', 'g')
    var re5 = new RegExp('＇', 'g')
    var msg = mixContent.replace(re1, '<') // 执行替换成空字符
    var msg2 = msg.replace(re2, '>') // 执行替换成空字符
    var msg3 = msg2.replace(re4, '"') // 执行替换成空字符
    var msg4 = msg3.replace(re5, "'") // 执行替换成空字符
    return msg4
  } else {
    return ''
  }
}


export function copyText(text) {
  const input = document.createElement('input')
  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
}



// 倒计时函数
export function timeCountDown(time = 60, callback) {

	let timer = null;
	let count = time;

	function updateTimer() {
		count--;
		if (count <= 0) {
			clearInterval(timer);
			callback('重新获取',true);
		}else{
			callback(count + 's');
		}
	}
	timer = setInterval(updateTimer, 1000);
}