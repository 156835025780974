<template>
    <div class="priceIndex">
        <div class="price-top"></div>
        <div class="price-con">
            <div class="top flex_around">
                <div class="top-left ">
                    <div class="tit text-center ">试用7天</div>
                    <div class=" sub text14">需要更多的咨询请联系知视猫客服</div>
                    <div class="list text14 ">
                        <a target="_black" v-for="item of data.enrollList" class="flex-center" :href="item.remarks">
                                                {{item.dictLabel}}
                                                <img src="https://file.jinglinshe.com/kittyhow/iconPark-arrow-right%403x.png"/>
                                        </a>
                        <div v-if="data.enrollList" @click="otherQuestion('#otherQuestion')" class="flex-center pointer">
                            其他常见问题
                            <img src="https://file.jinglinshe.com/kittyhow/iconPark-arrow-right%403x.png" />
                        </div>
                        <!-- <div class="flex-center">
                                                课程类目开放表查看
                                                <img src="https://file.jinglinshe.com/kittyhow/iconPark-arrow-right%403x.png" />
                                            </div>
                                            <div class="flex-center">
                                                知视猫品牌介绍
                                                <img src="https://file.jinglinshe.com/kittyhow/iconPark-arrow-right%403x.png" />
                                            </div>
                                            <a class="flex-center" href="https://kittyhow.com/login/#/tutorial/detail">
                                                其他常见问题
                                                <img src="https://file.jinglinshe.com/kittyhow/iconPark-arrow-right%403x.png"/>
                                            </a> -->
                    </div>
                </div>
                <div class="top-right flex_around">
                    <div>
                        <div class="tit flex-center text20"><img src="https://file.jinglinshe.com/kittyhow/小程序-F.png" />多端版</div>
                        <div class="text14 desc text-center">支持抖音、抖音橱窗、快手、微信、视频号、小红书等多端短视频&直挂载，满足名知识变现场景需求，轻松开启知识付费
                        </div>
                        <button @click="$emit('showModal')" class="button-reset">立即体验</button>
                    </div>
                    <div>
                        <div class="tit flex-center text20 "><img src="https://file.jinglinshe.com/kittyhow/vip.png" />品牌版</div>
                        <div class="text14 desc text-center">独立客服系统根据品牌协调性定制 独立页面logo以及个性化定制服务 满足不同行业需求
                        </div>
                        <button class="button-reset">暂不支持</button>
                    </div>
                </div>
            </div>
            <div class="funs">
                <div class="funs-tit flex-center">
                    <div class="text18 tit">
                        功能分类
                    </div>
                    <div class="flex-center right text14">
                        <div>抖音</div>
                        <div>快手</div>
                        <div>微信</div>
                    </div>
                </div>
                <div class="con" v-for="item of priceList">
                    <span class="text18 red title">{{item.title}}</span>
                    <div class="cont2 text14">
                        <div class="flex_around" v-for="detailItem of item.list">
                            <div class="l">{{detailItem.title}}</div>
                            <div class="flex-center right">
                                <div class="flex-center text-right">
                                    <img v-if="!detailItem.type1" src="https://file.jinglinshe.com/kittyhow/riFill-checkbox-circle-fill%20Copy%2053%403x.png" />
                                    <img v-else-if="detailItem.type1==2" src="https://file.jinglinshe.com/kittyhow/riFill-close-circle-fill%20Copy%403x.png" />
                                </div>
                                <div class="flex-center text-right">
                                    <img v-if="!detailItem.type2" src="https://file.jinglinshe.com/kittyhow/riFill-checkbox-circle-fill%20Copy%2053%403x.png" />
                                    <img v-else-if="detailItem.type2==2" src="https://file.jinglinshe.com/kittyhow/riFill-close-circle-fill%20Copy%403x.png" />
                                </div>
                                <div class="flex-center text-right">
                                    <img v-if="!detailItem.type3" src="https://file.jinglinshe.com/kittyhow/riFill-checkbox-circle-fill%20Copy%2053%403x.png" />
                                    <img v-else-if="detailItem.type3==2" src="https://file.jinglinshe.com/kittyhow/riFill-close-circle-fill%20Copy%403x.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="con con2 flex_around">
                    <div class="con2_left">
                        <div class="text28 left_tit">知视猫讲师</div>
                        <div class="desc text14">对知识内容创作者而言，创造知识的价值既是提升创作者营收的关键也是巨大的挑战。 我们为所有知识内容创造者或机构提供知识变现内容解决方案，专为内容创造者打造 短视频平台知识店铺，助力知识创造价值。
                        </div>
                        <div class="flex">
                            <button @click="$emit('showModal')" class="button-reset">立即体验</button>
                            <button class="button-cancel"> 
                                <router-link to="/desc" >
                                   了解更多
                                </router-link>
                             </button>
                        </div>
                    </div>
                    <div class="con2_right">
                        <img src="https://file.jinglinshe.com/kittyhow/资费-内容创作.jpg" />
                    </div>
                </div>
                <div class="con con3" id="otherQuestion">
                    <div class="" @click="item.show=!item.show" v-for="item of data.questionList">
                        <div class="question flex-center flex_around">
                            <div class="top-l text18 flex-center">
                                <div class="q text-center text14">Q</div>
                                {{item.question}}
                            </div>
                            <div class="top-r">
                                <img v-if="item.show==false" src="https://file.jinglinshe.com/kittyhow/antOutline-right%403x.png" />
                                <img v-else src="https://file.jinglinshe.com/kittyhow/antOutline-down%403x.png" />
                            </div>
                        </div>
                        <div class="answer flex" v-if="item.show==true">
                            <div class="a  q text-center">A</div>
                            <div class="text14 desc" v-html="item.answer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    import {
        priceList
    } from "@/utils/json"
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    import {
        contextRegExp2
    } from "@/utils/index"
    const data = reactive({
        enrollList: [], //入驻列表
        questionList: [], //问题列表
        priceList //列表渲染
    });
    // 根据分类获取帮助中心详情
    const qaListData = async() => {
        try {
            const response = await $axios.get('/zsm/api/qa/listData?qaDict=19');
            response.data.list.map((item) => {
                item.show = false;
                item.answer = contextRegExp2(item.answer)
            })
            data.questionList = response.data.list;
        } catch (error) {
            console.error('请求失败:', error)
        }
    }
    //   其他常见问题滑动到固定位置
    const otherQuestion = async(ele) => {
        const nav = document.querySelector(ele);
        window.scrollTo({
            top: nav.offsetTop - 100,
            left: 0,
        });
    }
    // 需要更多的咨询请联系知视猫客服
    const getDictList = async() => {
        try {
            const response = await $axios.post('/zsm/api/setting/dict?dictType=zsm_web_price_page_enroll_list');
            data.enrollList = response.data.data;
        } catch (error) {
            console.error('请求失败:', error)
        }
    }
    onMounted(async() => {
        qaListData()
        getDictList()
    })
    onUnmounted(() => {})
</script>
<style lang="scss" scoped>
    @import '@/styles/price.scss';
</style>