<template>
  <div class="journalismIndex ">
    <div class="price-top"></div>
    <div class="price-con flex_around  container">
      <div class="containerL">
        <div class="top">
          <span class="text28">{{data.qaListDetail.question}}</span>
          <div class="top-bottom text12">
            <span>{{data.qaListDetail.createDate}}</span>
            <span>阅读量： {{data.qaListDetail.goodCount}}</span>
          </div>
        </div>
        <div class="content" v-html="data.qaListDetail.answer"></div>
      </div>
      <div class="containerR">
        <div class="tit text20">推荐文章</div>
        <div id="reportList" class="report_list">
          <router-link :to="'/journalism?id='+item.id" v-for="item of data.qaList">
            <div class="report_cell">
              <div class="report_img">
                <img :src="item.images" alt="">
              </div>
              <div class="report_title text14">{{item.question}}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import {
    useRoute
  } from 'vue-router'
  import {
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch
  } from 'vue';
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  import {
    contextRegExp2
  } from "@/utils/index"
  const data = reactive({
    qaListDetail: "", // 问题详情
    qaList: [] // 问题列表
  });
  // 根据分类获取帮助中心详情
  const qaListData = async() => {
    try {
      //  const response = await $axios.get('/zsm/api/qa/listData?qaDict=1002');
      const response = await $axios.get('/zsm/api/qa/listData?qaDict=1001');
      response.data.list.map((item, index) => {
        if (item.id == route.query.id) {
          response.data.list.splice(index, 1)
        }
        item.answer = contextRegExp2(item.answer)
      })
      data.qaList = response.data.list.slice(0, 8);
    } catch (error) {
      console.error('请求失败:', error)
    }
  }
  // 路由
  const route = useRoute();
  watch(() => route.query, (newRoute, oldRoute) => {
    scoreQa(route.query.id)
  });
  // 根据分类获取帮助中心详情
  const qaListDetail = async(id) => {
    try {
      const response = await $axios.get(`/zsm/api/qa/get?id=${id}`);
      const head = document.head
      const meta = document.createElement('meta')
      meta.setAttribute('name', "description");
      meta.setAttribute('content', response.data.data.question);
      document.title = response.data.data.question
      head.appendChild(meta);
      response.data.data.answer = contextRegExp2(response.data.data.answer)
      data.qaListDetail = response.data.data;
    } catch (error) {
      console.error('请求失败:', error)
    }
  }
  // 增加浏览数
  const scoreQa = async(id) => {
    await $axios.get(`/zsm/api/qa/score?id=${id}&type=1`);
    qaListDetail(route.query.id)
  }
  onMounted(async() => {
    scoreQa(route.query.id)
    qaListData()
  })
  onUnmounted(() => {})
</script>
<style lang="scss" scoped>
  @import '@/styles/journalism.scss';
</style>