<template>
    <div class=" descIndex" ref="descIndex">
        <div class="top">
            <div class="left" style="padding-top:50px;">
                <div class="text40" style="width:500px">内容创作智能服务平台</div>
                <div class="text40"></div>
                <p class="text14 desc" style="margin-top:25px;">全方位功能体系帮助知识内容创造者更加快捷的搭建
                    知识店铺，完善的功能组件提高运营效率</p>
            </div>
            <img src="https://file.jinglinshe.com/kittyhow/讲师后台.jpg" />
        </div>
        <div class="navList flex">
            <a href="javascript:void(0)" @click="scrollTopView('#detailItem'+index,index)" class="navItem  text24 pointer" :href="'#detailItem'+index" :class="index == data.chooseNav ? 'active' : ''" v-for="(item, index) of data.nav" :key="index">
                                {{ item.title }}
                            </a>
        </div>
        <div class="descCon">
            <div>
                <div class="detailItem  " :id="'detailItem'+index" v-for="(item, index) of data.con.contentList">
                    <div class="flex_around">
                        <div class="detailLeft" v-if="item.position == 'left'">
                            <div class="tit">
                                <div class="text16 flex-center">
                                    <div class="pointer" :class="item.choose == detailIndex ? 'red' : ''" v-for="(detailItem, detailIndex) of item.tit">
                                        {{ detailItem }}
                                    </div>
                                </div>
                            </div>
                            <div class="desc-list">
                                <div class="text36 red" v-if="item.styles == 1">{{ item.descList[item.choose][0] }}</div>
                                <div class="text36">{{ item.descList[item.choose][1] }}</div>
                                <div class="subtit text15">{{ item.descList[item.choose][2] }}</div>
                                <div class="subtit text15" v-if="item.descList[item.choose][3]">
                                    {{ item.descList[item.choose][3] }}</div>
                                <button class="btn button-reset">{{ item.btn }}</button>
                            </div>
                        </div>
                        <div class="detailRight" v-if="item.position == 'left'">
                            <img :src="item.descImg[item.choose]" />
                        </div>
                        <div class="detailRight" v-if="item.position == 'right'">
                            <img :src="item.descImg[item.choose]" />
                        </div>
                        <div class="detailLeft" v-if="item.position == 'right'">
                            <div class="tit">
                                <div class="text16 flex-center">
                                    <div class="pointer" :class="item.choose == detailIndex ? 'red' : ''" v-for="(detailItem, detailIndex) of item.tit">
                                        {{ detailItem }}
                                    </div>
                                </div>
                            </div>
                            <div class="desc-list">
                                <div class="text36 red">{{ item.descList[item.choose][0] }}</div>
                                <div class="text36">{{ item.descList[item.choose][1] }}</div>
                                <div class="subtit text15">{{ item.descList[item.choose][2] }}</div>
                                <div class="subtit text15" v-if="item.descList[item.choose][3]">
                                    {{ item.descList[item.choose][3] }}</div>
                                <div class="subtit text15" v-if="item.descList[item.choose][4]">
                                    {{ item.descList[item.choose][4] }}</div>
                                <button @click="$emit('showModal')" class="btn button-reset">{{ item.btn }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div style="margin-top:30px;padding:109px 0 16px 0 ;background-color: rgba(247, 248, 249, 1);" class="sec2 sec23  detailItem" :id="'detailItem'+(data.con.contentList.length)">
            <section class="main_title">
                <div class="main_title">
                    <div class="main_font">{{ data.scenceCon.title }}</div>
                    <img alt="知视猫" :src="data.scenceCon.img">
                </div>
            </section>
        </div>



       
        <div class="sec2 detailItem" style="margin-top:96px;padding-top:0"  :id="'detailItem'+(data.con.contentList.length+1)">
            <section>
                <h2 class="text36">优质案例</h2>
                <div class="flex_between team-list">
                    <li class="team" v-for="item of data.shopList">
                        <img alt="知视猫" :src="item.img">
                        <div>
                            <div class="text14">{{item.tit}}</div>
                            <span class="text12">{{item.subTit}}</span>
                        </div>
                    </li>
                </div>
            </section>
            <div class="use_btn" @click="$emit('showModal')">立即加入</div>
        </div>
    </div>
</template>
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    import {
        shopJson
    } from "@/utils/json"
    import {
        shopList
    } from "@/utils/json"
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    const data = reactive({
        ...shopJson,
        chooseNav: 0, //选中的优惠
        shopList,
        scrollViewList: [] //元素内容
    });
    const scrollTopView = (ele, index) => {
        const nav = document.querySelector(ele);
        data.chooseNav = index
        let top = nav.offsetTop - 60;
        if(index==0){
            top = top - 120
        }else if(index==4){
            top = top - 190
        }else if(index==3){
            top = top - 90
        }
        window.scrollTo({
            top,
            left: 0,
            // behavior: 'smooth'
        });
    };
    // 滑动事件
    const scrolling = () => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const navList = document.querySelector(".navList");
        const descCon = document.querySelector(".descCon");
        if (scrollTop >= 310) {
            navList.style.position = 'fixed';
            navList.style.top = '110px';
            navList.style.left = 'calc(50vw - 643px)';
            descCon.style.paddingTop = '110px';
        } else {
            navList.style.position = 'relative';
            navList.style.top = 0;
            navList.style.padding = '0';
            navList.style.left = '0';
            descCon.style.paddingTop = '0';
        }
        for (let i in data.scrollViewList) {
            if (data.scrollViewList[i].offsetTop + 200 >= scrollTop) {
                data.chooseNav = i
                break;
            }
        }
    };
    onMounted(async() => {
        data.scrollViewList = document.querySelectorAll(".detailItem");
        window.addEventListener("scroll", scrolling);
    })
    onUnmounted(() => {
        window.removeEventListener("scroll", scrolling);
    })
</script>
<style lang="scss" scoped>
    @import '@/styles/desc.scss';
    .detailLeft {
        .red {
            color: $red3;
            font-family: SourceHanSansSC-medium;
        }
    }
    .red {
        font-weight: normal;
        color: $red3;
        font-family: SourceHanSansSC-bold;
    }
    .descIndex .descCon .detailLeft .desc-list .text36 {
        margin-bottom: 20px;
        line-height: 56px;
        &.red {
            margin-bottom: 2px;
        }
    }
    .navList{
        margin-bottom:74px;
    }
</style>