<template>
  <div class="secClassifyCourse">
    <div class="col-md-12">
      <h3>课程热销榜</h3>
    </div>
    <div class="courseAll">
      <div class="row-bg flex " style="flex-wrap: wrap;padding: 0;margin: 0;" :gutter="10">
        <div class="el-col" v-for="item of goodList" style="width: calc(25% - 15px);margin: 0  15px 15px 0;padding: 0 0 20px 0 ;">
          <div class="grid-content" @click="courseDetail(item)">
            <div class="fodder-operation">
              <img :src="item.cover" alt="">
            </div>
            <div class="fodder-box-author">{{ item.title }} </div>
            <div class=" fodder-titles flex">
              <div style="width: calc(100% - 80px );">{{ item.subTitle }}</div>
              <span v-if="item.price" style="color: #f00;">￥{{ item.price }}</span>
              <span v-else style="color: #ff7d8d;">￥0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BASE_URL from '@/utils/BASE_URL';
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  export default {
    data() {
      return {
        goodList: [] //课程列表
      }
    },
    mounted() {
      this.getGoodList()
    },
    methods: {
      // 商品列表
      async getGoodList() {
        const response = await $axios.get(BASE_URL.ZSM_BASE_GOOD + '/zsm/api/alipay/listData?type=1');
        this.goodList = response.data.list;
      },
      // 课程详情
      courseDetail(info) {
        this.$router.push(`/pay/detail?id=${info.id}`)
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
  .secClassifyCourse {
    padding: 20px 0px 50px 0;
    min-height:calc(100vh - 491px);
    .col-md-12 {
      h3 {
        font-size: 22px;
        margin-bottom:20px;
        line-height: 50px;
        color: #000000;
        font-weight:normal;
         font-family: SourceHanSansSC-bold;
      }
    }
  }
  
  .courseAll {
      .fodder-operation {
        /* */
        img {
          width: 100%;
          height: 192px;
          -o-object-fit: cover;
          object-fit: cover;
          vertical-align: top;
        }
      }
      .fodder-box-author {
        font-weight: bold;
        color: #000 !important;
        font-size: 16px;
        margin: 10px 15px 0 15px;
      }
      .fodder-titles {
        align-items: center;
        font-size: 14px;
        margin: 10px 15px 0 15px;
        line-height: 24px;
        justify-content: space-between;
        color: #000;
        div {
          color: #CCCCCC;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .el-col {
        background-color: #fff;
        padding-bottom: 20px;
        margin-bottom: 24px;
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 5%);
        transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .4s ease-out;
        transition-delay: .1s;
        transform: translateZ(0);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
      }
    }
</style>
