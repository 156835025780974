<template>
    <div>
        <div class="service">
            <div class="content-box text-center">
                <div class="top-title">官方电话</div>
                <div class="flex text-center flex-center bto-title"><img src="https://file.jinglinshe.com/kittyhow/services.png" alt="">
                    <div>400-0085-512</div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div  id="chatBtn" @click="chatBtnFn" :class="data.chatBtn?'chatBtnS':'chatBtnH'" class="pointer chatBtn flex" style="">
            <img width="25px" height="25px" src="https://file.jinglinshe.com/services2.png" style="margin: 0px 5px -6px 0px;">
            <span>咨询客服</span>
            <span id="qimo_badge"></span>
        </div>
    </div>
</template>
<!-- 这是美洽的嵌入代码 -->
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    const data = reactive({
        chatBtn: true
    });
    // 客服按钮
    const chatBtnFn = () => {
        _MEIQIA('showPanel');
        // 监听客服窗口是否存在
        _MEIQIA('getPanelVisibility', function(visible) {
            data.chatBtn = visible == 'visible' ? false : true;
        });
    };
</script>
<style lang="scss" scoped>
    @import '@/styles/service.scss';
</style>
