<template>
    <div class="priceIndex">
        <div class="price-con">
            <div class="top flex-center">
                <div class="topL">
                    <div class="text28">看见知识，创造价值！</div>
                    <div class="topBL  flex-center text18">
                        <img src="https://file.jinglinshe.com/kittyhow/mb-search@3x.png" /> 抖音、快手、微信、搜索知视猫
                    </div>
                </div>
            </div>
            <div class="study-list flex flex_between">
                <div class="study-item ">
                    <div class="itemT text18 flex_row_center flex-center">
                        <img src="https://file.jinglinshe.com/kittyhow/douyin.png" />
                        <span>抖音</span>
                    </div>
                    <div class="itemB text-center">
                        <div class="tit text16">抖音端看课程</div>
                        <div class="subT text12">
                            打开抖音APP, 扫码可进入抖音“知视猫” 小程序，点击"已购课程" 或底部 "学习" 按键 即可观看课程
                        </div>
                        <img src="https://file.jinglinshe.com/kittyhow/douyinErcode.png" />
                    </div>
                </div>
                <div class="study-item ">
                    <div class="itemT text18 flex_row_center flex-center">
                        <img src="https://file.jinglinshe.com/kittyhow/kuaishou.png" />
                        <span>快手</span>
                    </div>
                    <div class="itemB text-center">
                        <div class="tit text16">快手端看课程</div>
                        <div class="subT text12">
                            打开快手APP, 扫码可进入快手“知视猫”小程序，点击"已购课程" 或底部 "学习" 按键即可观看课程
                        </div>
                        <img src="https://file.jinglinshe.com/kittyhow/微信公众号.png" />
                    </div>
                </div>
                <div class="study-item ">
                    <div class="itemT text18 flex_row_center flex-center">
                        <img src="https://file.jinglinshe.com/kittyhow/weixin.png" />
                        <span>微信</span>
                    </div>
                    <div class="itemB text-center">
                        <div class="tit text16">微信端看课程</div>
                        <div class="subT text12">
                            打开微信APP, 扫码可进入微信“知视猫” 小程序，点击"已购课程" 或底部 "学习" 按键 即可观看课程
                        </div>
                        <img src="https://file.jinglinshe.com/kittyhow/gh_147315cd7261_258%20%281%29.jpg" />
                    </div>
                </div>
                <div class="study-item ">
                    <div class="itemT text18 flex_row_center flex-center">
                        <img src="https://file.jinglinshe.com/kittyhow/pc.png" />
                        <span>电脑端</span>
                    </div>
                    <div class="itemB text-center">
                        <div class="tit text16">电脑端看课程</div>
                        <div class="subT text12">
                            点击下方“学员登录”按键，使用您在 小程序内容授权维护的手机号码登录，即可 观看课程（注意：课程老师需要开放电脑端 学习才可以使用）
                        </div>
                        <button class="button-reset text14" @click="webStudy">学员登录</button>
                    </div>
                </div>
            </div>
            <div class="enrollForm flex-center flex_between">
                <div class="enrollL">
                    <img src="https://file.jinglinshe.com/kittyhow/enroll.png" />
                </div>
                <div class="enrollR ">
                    <p class="text22">申请入驻知视猫卖课</p>
                    <p class="text22">我们的商务顾问将第一时间为您服务</p>
                    <div class="form flex ">
                        <div>
                            <label class="text14">姓名</label>
                            <input maxlength="16" type="text" v-model="form.name" />
                        </div>
                        <div>
                            <label class="text14">课程类型</label>
                            <input type="text" v-model="form.goodType" />
                        </div>
                        <div>
                            <label class="text14">手机号</label>
                            <input maxlength="11" @input="changeMobile" type="text" v-model="form.mobile" />
                            <span class="text13 pointer" @click="sendMsgCode">{{verificate.tit}}</span>
                        </div>
                        <div>
                            <label class="text14 pointer">验证码</label>
                            <input maxlength="8" v-model="form.code" type="text" />
                        </div>
                    </div>
                    <button class="button-reset text14" @click="$emit('userEnroll', form);">立即入驻</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import {
        ElMessage
    }
    from 'element-plus'
    import {
        useRoute
    } from 'vue-router'
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref,
        watch,
        message
    } from 'vue';
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    import {
        timeCountDown
    } from '@/utils/index';
    let form = reactive({
        name: "", //姓名
        goodType: "", //课程类型
        mobile: "", //手机号
        code: "", //验证码
    });
    const verificate = reactive({
        flag: true, //状态，是否可以发送短信
        tit: "发送验证码", //状态，是否可以发送短信
    })
    /**
     * 数据重置
     */
    const resetForm = (e) => {
        for (let i in form) {
            form[i] = ''
        }
    };
    /**
     * 修改手机号
     * @param {String} e 输入内容
     */
    const changeMobile = (e) => {
        form.mobile = form.mobile.replace(/[^0-9-]+/g, '$1');
    }
    // 电脑端学习
    const webStudy = async() => {
        window.open('https://kittyhow.com/study', '_black')
    }
    // 获取验证码
    const sendMsgCode = async() => {
        if (form.mobile.length == 11) {
            const response = await $axios.post(`/zsm/api/sms/sendMsg?mobile=${form.mobile}&msgDict=zsm.sms.templateCode.enroll`);
            if (response.data.result == 'true') {
                if (verificate.flag == true) {
                    timeCountDown(60, (res, status) => {
                        if (status) {
                            verificate.flag = status;
                        }
                        verificate.tit = res
                    });
                }
                ElMessage({
                    customClass: 'ele',
                    message: '发送成功！',
                    type: 'success'
                })
            }
        } else {
            ElMessage({
                customClass: 'ele',
                message: '请输入正确的手机号',
                type: 'error'
            })
        }
    }
    onMounted(async() => {})
    onUnmounted(() => {})
</script>
<style lang="scss" scoped>
    @import '@/styles/price.scss';
    @import '@/styles/study.scss';
</style>