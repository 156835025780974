<template>
  <div style=" margin-top:80px;">
    <div class="video-index">
      <video loop="loop" autoplay="autoplay" src="https://file.jinglinshe.com/zsm-about-us-top-video.mp4" class="video-tvc" id="video-tvc" data-object-fit=""></video>
    </div>
    <div class="aboutIndex priceIndex">
      <div class="price-con">
        <div>
          <div class="con text-center">
            <div class="title text36 margin-t75">什么是知视猫</div>
            <div class="desc margin-auto ">
              <p class=" text16 text-left margin-t75">
                知视猫是一站式知识变现平台，专注泛知识视频变现解决方案的服务商，为达人/机构/企业提供定制化抖音/快手可挂载专属小程序，快速搭建独立知识付费小程序，打造品牌商城，凸显品牌形象。为达人提供从引流、获客，到运营、转化的内容变现全链服务，为用户提供健身瑜伽、亲子教育、商业营销等各个领域的干货技能课程。
              </p>
            </div>
            <div class="container margin-t75">
              <div class="card-wrapper ">
                <div class="img-card">
                  <div class="front" :style="{'background-image':'url(https://file.jinglinshe.com/aboutus_2-1.png)'}">
                    <div class="mask flex_column"><span class="index text18">01</span> <span class="tab text24">类目</span></div>
                  </div>
                  <div class="card-back">
                    <div class="back pointer  pointertext14 flex_column"><span class="index text18">01</span> <span class="tab text24">类目</span> <span class="line"></span>
                      <span class="description pointer text14 chinese">知视猫平台目前内容覆盖美妆教学、技能培训、软件教学、亲子教育、运动瑜伽、音乐教学、商业管理、数字运营等各个泛知识领域、超过2亿的曝光。</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-wrapper ">
                <div class="img-card">
                  <div class="front" :style="{'background-image':'url(https://file.jinglinshe.com/aboutus_1.png)'}">
                    <div class="mask flex_column"><span class="index text18">02</span> <span class="tab text24">服务</span></div>
                  </div>
                  <div class="card-back">
                    <div class="back pointer  pointertext14 flex_column"><span class="index text18">02</span> <span class="tab text24">服务</span> <span class="line"></span>
                      <span class="description pointer text14 chinese">知视猫的服务围绕“内容创造者”这一核心服务对象，整合泛知识内容变现资源，帮助内容创造者更好的推广课程，提供一站式全链路营销工具和服务。</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-wrapper ">
                <div class="img-card">
                  <div class="front" :style="{'background-image':'url(https://file.jinglinshe.com/zsm-h5-bg-img6.png)'}">
                    <div class="mask flex_column"><span class="index text18">03</span> <span class="tab text24">内容挂载</span></div>
                  </div>
                  <div class="card-back">
                    <div class="back pointer  pointertext14 flex_column"><span class="index text18">03</span> <span class="tab text24">内容挂载</span> <span class="line"></span>
                      <span class="description pointer text14 chinese">知视猫的内容挂载是通过抖音、快手等平台的短视频或直播通道进行课程的挂载和推广。</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ambition-list flex margin-t100">
              <p class="item">
                <span class="detail-wrapper">
        							<span class="image-wrapper">
        								<img src="https://file.jinglinshe.com/2bc643c9-9b1e-4fe8-bb7a-772c373a2bc0.png" class="image">
        							</span>
                <span class="title">愿景</span>
                <span class="detail">成为最受讲师信任和欢迎的知识变现平台</span>
                </span>
                <span class="line"></span>
              </p>
              <p class="item">
                <span class="detail-wrapper">
        							<span class="image-wrapper">
        								<img src="https://file.jinglinshe.com/d21c3e88-57b5-4a7f-95e4-8b84944b9394.png" class="image">
        							</span>
                <span class="title">使命</span>
                <span class="detail">为用户探索和发现更多优质的课程</span>
                </span>
                <span class="line"></span>
              </p>
              <p class="item">
                <span class="detail-wrapper">
        							<span class="image-wrapper">
        								<img src="https://file.jinglinshe.com/3c7877b9-032c-4509-8af1-48ae559dbab0.png" class="image">
        							</span>
                <span class="title">企业价值观</span>
                <span class="detail">积极向上、走进讲师和用户、务实、担当</span>
                </span>
                <!-- <span class="line"></span> -->
              </p>
            </div>
            <div class="text-center aboutUsT ">
              <div class="title text36 ">我们的方式</div>
              <div class="usType grid margin-t75">
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/ABOUT1.png" />
                  <p class="text20">质量</p>
                  <span class="text14">每个人都有提新的建议或对现有的规则提建议的权力</span>
                </div>
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/about2.png" />
                  <p class="text20">数据法制</p>
                  <span class="text14">数据说明一切，有了数据信息的支持我们才能不断的前行</span>
                </div>
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/3-Humble.png" />
                  <p class="text20">保持谦逊</p>
                  <span class="text14">数据说明一切，有了数据信息的支持我们才能不断的前行</span>
                </div>
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/4-Celebrate.png" />
                  <p class="text20">庆祝</p>
                  <span class="text14">当我们完成目标后，我们要享受这份庆祝的时光</span>
                </div>
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/5-Approachable.png" />
                  <p class="text20">我们的原则</p>
                  <span class="text14">每个人都有提新的建议或对现有的规则提建议的权力</span>
                </div>
                <div class="text-left">
                  <img src="https://file.jinglinshe.com/kittyhow/6-Speed.png" />
                  <p class="text20">快速迭代</p>
                  <span class="text14">快速响应能让我们有足够的时间去尝试新的事物，去学习，去归总经验</span>
                </div>
              </div>
            </div>
            <div class="text-left flex usType2 margin-t100">
              <div>
                <span class="tit text32">关于我们</span>
                <p class="desc2 text14">一群渴望分享热爱生活的90后、00后怀揣梦想，筑梦而生。经过几年的用心成长，越来越多的小伙伴加入知视猫的团队。了解需求、助知识创造价值” 是公司发展的初衷，并且一路贯彻执行。 </p>
                <div class="flex pointer icon">
                  <div @click="jumpLink(1)" class="text-center flex-center flex_row_center">
                    <img src="https://file.jinglinshe.com/kittyhow/semiDesign-semi-icons-tiktok_logo%403x.png" />
                  </div>
                  <div @click="jumpLink(2)" class="text-center flex-center flex_row_center">
                    <img src="https://file.jinglinshe.com/kittyhow/图片 3@3x.png" />
                  </div>
                  <div @click="jumpLink(3)" class="text-center flex-center flex_row_center">
                    <img src="https://file.jinglinshe.com/kittyhow/if-wechat@3x.png" />
                  </div>
                  <div @click="jumpLink(4)" class="text-center flex-center flex_row_center">
                    <img src="https://file.jinglinshe.com/kittyhow/antFill-zhihu-circle@3x.png" />
                  </div>
                  <div @click="jumpLink(5)" class="text-center flex-center flex_row_center">
                    <img src="https://file.jinglinshe.com/kittyhow/riFill-mail-fill@3x.png" />
                  </div>
                  <span class="remarks text12">成为知视猫的一员？</span>
                </div>
              </div>
              <div class="right flex">
                <img src="https://file.jinglinshe.com/kittyhow/Untitled-11.jpg" />
                <img src="https://file.jinglinshe.com/kittyhow/Untitled21-11.jpg" />
              </div>
            </div>
            <div class="flex bottom">
              <img src="https://file.jinglinshe.com/kittyhow/catgif.gif" />
              <img src="https://file.jinglinshe.com/kittyhow/gallery12.png" />
              <img src="https://file.jinglinshe.com/kittyhow/xueying.jpg" />
              <img src="https://file.jinglinshe.com/kittyhow/gallery19.png" />
              <img src="https://file.jinglinshe.com/kittyhow/gakkery90.jpg" />
              <img src="https://file.jinglinshe.com/kittyhow/grally73.jpg" />
              <img src="https://file.jinglinshe.com/kittyhow/Gallery6.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import {
    onMounted,
    onUnmounted,
    reactive,
    ref
  } from 'vue';
  import {
    aboutUsJumpLink
  } from '@/utils/json';
  import {copyText} from "@/utils/index";
  // import {
  //   aboutUs
  // } from "@/utils/json";
  // const data = reactive({
  //   ...aboutUsJumpLink
  // });
  /**
   * @description: 跳转链接
   * @param {*} type 类型 1抖音 2快手 3微信 4知乎 5 邮箱
   */
  const jumpLink = async(type) => {
    let link = aboutUsJumpLink.filter((item) => {
      return item.id == type
    })[0].link || '';
     window.open(link, '_black')
    // if (type == 3) {
    //   copyText(link)
    //   alert("微信号复制成功,点击确认打开微信~", 1500);
    //   window.location.href = 'weixin://';
    // } else {
     
    // }
  };
  onMounted(() => {
    // console.log('====================================');
    // console.log(JSON.stringify(data));
    // console.log('====================================');
  })
</script>
<style lang="scss" scoped>
  @import '@/styles/about.scss';
</style>