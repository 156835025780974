<template>
    <div class=" descIndex" ref=" ">
        <div class="top">
            <div class="left">
                <div class="text36">助力知识博主内容IP孵化</div>
                <p class="text14 desc">基于知识内容创作与知识变现为核心，依托知视猫丰富的知识付费赛道经验</p>
            </div>
            <img src="https://file.jinglinshe.com/kittyhow/知视学苑.jpg" />
        </div>
        <div class="desc-container">
            <div class="fuhua">
                <span class=" text36">知视猫 · 孵化服务</span>
                <div class="fuhua-list flex flex_between">
                    <div :style='{ "background-image": "url(" + item.bg + ")" }' class="fuhua-item" v-for="item of data.fuhua.list">
                        <div class="tit text18">{{ item.tit }}</div>
                        <div class="subTit text14">{{ item.subTit }}</div>
                    </div>
                </div>
            </div>
            <div class="ugczhuanhua fuhua">
                <div v-for="(item, index) of data.ugczhuanhua">
                    <span class=" text36 titTop" v-if="index == 0">知视猫 · UGC转化</span>
                    <span class=" text36 titTop" v-else-if="index == 1">知视猫 · 持续为客户创造价值</span>
                    <span class=" text36">{{ item.tit }}</span>
                    <div class="flex ugczhuanhua-item flex_between" v-for="detailItem of item.child">
                        <div class="detailItemL">
                            <div class="text28 titT">{{ detailItem.tit }}</div>
                            <div class="subItem">
                                <div class="text14" v-for="subItem of detailItem.subt">
                                    {{ subItem }}
                                </div>
                            </div>
                            <div class="tagslist">
                                <span class="text15">{{ detailItem.tags.tit }}</span>
                                <div class="flex list">
                                    <div class="text14 tagsItem" v-for="tagsItem of detailItem.tags.list">{{ tagsItem }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detailItemR " v-if="!detailItem.rightList">
                            <img :src="detailItem.bg" />
                        </div>
                        <div class="detailItemR2 flex" v-else>
                            <div @mouseenter="mouseEnterDetail(rightItem,rightIndex)" class="flex flex_between pointer" v-for="(rightItem, rightIndex) of detailItem.rightList">
                                <img :src="rightItem.bg" />
                                <div v-show="rightItem.showFlag == true" class="rightP" :style="{ 'background-color': rightItem.showFlag == true? 'rgba(0, 0, 0, 0.8)':'' }">
                                    <div class="text18 rightPT">{{ rightItem.tit }}</div>
                                    <div class="rightTags rightPT flex">
                                        <div class="text14" v-for="tagsItem of rightItem.tags">
                                            {{ tagsItem }}
                                        </div>
                                    </div>
                                    <!-- <div @click="journalismDetail(rightItem.id)" v-if="rightItem.detailTit" class="detailTit flex align-center detailBot text14 pointer">{{ rightItem.detailTit }}
                                        <img src="https://file.jinglinshe.com/kittyhow/antOutline-caret-right%403x.png" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zhaoshangImg">
                <img :src="data.zhaoshangImg" alt="">
            </div>
            <!-- <div class="sec2 margin-t100">
                                    <section>
                                        <div class="main_title">
                                            <div class="main_font">{{data.con.shopTit}}</div>
                                        </div>
                                        <div class="flex_between team-list margin-t50">
                                            <li class="team" v-for="item of data.shopList">
                                                <img alt="知视猫" :src="item.bizCover">
                                                <div>{{item.bizName}}</div>
                                            </li>
                                        </div>
                                    </section>
                                </div> -->
            <div class="sec2">
                <section>
                    <h2>看见知识、创造价值</h2>
                    <div class="flex_between team-list">
                        <li class="team" v-for="item of data.shopList">
                            <img alt="知视猫" :src="item.img">
                            <div>
                                <div class="text14">{{ item.tit }}</div>
                                <span class="text12">{{ item.subTit }}</span>
                            </div>
                        </li>
                    </div>
                </section>
                <div class="use_btn" @click="$emit('showModal')">立即加入</div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    import {
        academy
    } from "@/utils/json"
    import {
        useRouter
    } from 'vue-router';
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    import {
        shopList
    } from "@/utils/json"
    const data = reactive({
        ...academy,
        chooseNav: 0, //选中的优惠
        shopList,
        scrollViewList: [] //元素内容
    });
    // 内容案例滑入事件
    const mouseEnterDetail = async(rightItem, rightIndex) => {
        data.ugczhuanhua[1].child[1].rightList.map((item, index) => {
            item.showFlag = false;
            if (index === rightIndex) {
                item.showFlag = true;
            }
        });
    }
    // 根据分类获取帮助中心详情
    const qaListData = async() => {
        try {
            const response = await $axios.get('/zsm/api/qa/listData?qaDict=1001');
             let list = response.data.list.slice(7, 11);
            data.ugczhuanhua[1].child[1].rightList.map((item,index) => {
                item.id = list[index].id;
                item.bg = list[index].images;
            });
        } catch (error) {
            console.error('请求失败:', error)
        }
    }
    const router = useRouter();
    // 查看新闻详情
    const journalismDetail = async(id) => {
        router.push({
            path: '/journalism',
            query: {
                id
            }
        })
    }
    // 获取首页相关数据（用于合作老师渲染）
    // const shopHome = async () => {
    //     try {
    //         const res = await $axios.get('/zsm/api/om/listTopic?classifyCode=haoke&appFlag=1');
    //         res.data.map((item) => {
    //             if (item.rowNum == 3) {
    //                 data.shopList = item.omTopicBizList;
    //             }
    //         })
    //     } catch (error) {
    //         console.error('请求失败:', error)
    //     }
    // }
    onMounted(async() => {
        // shopHome()
        qaListData()
    })
</script>
<style lang="scss" scoped>
    @import '@/styles/academy.scss';
    @import '@/styles/desc.scss';
</style>