<template>
   <div id="footer">
      <footer class="footer">
         <div class="foot_box">
            <div class="foot1">
               <div style="width: 30%">
                  <div class="foot_title">关于我们</div>
                  <div>
                     <router-link to="/about">关于知视猫</router-link>
                  </div>
                  <div>
                     <router-link to="/price">支付担保</router-link>
                  </div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/agreement">用户协议</a>
                  </div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/agreement">隐私条款</a>
                  </div>
               </div>
               <div style="width: 40%">
                  <div class="foot_title">产品服务</div>
                  <div>
                     <router-link to="/desc">知视猫小程序</router-link>
                     <!-- <a target="blank" >知视猫小程序</a> -->
                  </div>
                  <div>
                     <router-link to="/marketing">知视猫分销系统</router-link>
                     <!-- <a target="blank">知视猫分销系统</a> -->
                  </div>
                  <div>
                     <router-link to="/academy">知视学苑</router-link>
                  </div>

                    <div>
                     <!-- <router-link to="/pay">商城</router-link> -->
                  </div>
               </div>
               <div style="width: 30%">
                  <div class="foot_title">内容变现</div>
                  <div>
                     <router-link to="/live">短视频挂载</router-link>
                  </div>
                  <div>
                     <router-link to="/live">直播间挂载</router-link>
                  </div>
                  <div>
                     <router-link to="/marketing" tag="span">活动营销</router-link>
                  </div>
                  <div>
                     <router-link to="/marketing#detailItem=1" tag="span">分销达人</router-link>
                  </div>
               </div>
               <div style="width:30%">
                  <div class="foot_title">帮助中心</div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/tutorial/detail?id=1723697106627420160">如何入驻知视猫</a>
                  </div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/tutorial/detail?id=1736214257999732736">短视频/直播挂载</a>
                  </div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/tutorial/detail?id=1737102886178754560">资金收益</a>
                  </div>
                  <div>
                     <a target="blank" href="https://kittyhow.com/login/#/tutorial/detail?id=1730599498821283840">上传课程</a>
                  </div>
               </div>
            </div>
            <div class="foot2">
               <div>
                  <div>
                     <div class="foot_title">微信公众号</div>
                     <img alt="知视猫" src="https://file.jinglinshe.com/kittyhow/微信公众号.png">
                  </div>
                  <div>
                     <div class="foot_title">官方抖音号</div>
                     <img alt="知视猫" src="https://file.jinglinshe.com/kittyhow/%E6%8A%96%E9%9F%B3%E5%AE%98%E6%96%B9%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg">
                  </div>
                  <div>
                     <div class="foot_title">官方快手号</div>
                     <img alt="知视猫" src="https://file.jinglinshe.com/kittyhow/快手小程序.png">
                  </div>
               </div>
               友情链接：  <a target="blank" href="https://partner.open-douyin.com/service-market/market-detail/7075214854126081038?enter_from=search_page">抖音服务商平台</a>   <a target="blank" href="https://trendinsight.oceanengine.com/arithmetic-index">巨量学</a> 
            </div>
            <div class="foot3">
               <div style="text-align: left">
                  <div style="display:flex;align-items:center">
                     <img alt="知视猫" src="https://file.jinglinshe.com/kittyhow/kefu_icon.png">
                     <div class="kefu text13">客服电话</div>
                  </div>
                  <h2 class="text14">400-0085-512</h2>
                  <div>服务时间： 09:00-18:00</div>
                  <div>商务合作： 微信:askeddie</div>
               </div>
            </div>
         </div>
         <div class="bottom_tip">
            <p>
               <a style="color: #a1a6b2" href="http://beian.miit.gov.cn/" target="_blank">苏ICP备 20004162号-4</a> 苏州无用科技有限公司 | 出版物经营许可证：新出发苏园Y字第0476号 | 增值电信业务经营许可证：苏B2-20220615 | 2022 苏公网安备 32059002003935号
            </p>
         </div>
      </footer>
   </div>
</template>
<script setup>
   import {
      onMounted,
      onUnmounted,
      reactive
   } from 'vue'
   import {
      $axios
   } from '@/utils/request'; // 从 request.js 中导入 $axios
   const data = reactive({
      erCodeList: [] //客服二维码列表
   });
   // 新版官网---- 公告文字配置
   const getDictList = async() => {
      try {
         const response = await $axios.post('/zsm/api/setting/dict?dictType=zsm_web_warn_title');
         data.erCodeList = response.data.data;
      } catch (error) {
         console.error('请求失败:', error)
      }
   }
   onMounted(() => {
      // 获取配置文字
      getDictList()
   })
</script>

<style lang="scss" scoped>
   @import '@/styles/footer.scss';
</style>