<template>
  <div class="waring_tip" v-if='data.warnTitle&&(route.path=="/"||route.path=="/home")'>
    {{data.warnTitle}}
  </div>
  <div class="video_text" :style="{'top':data.warnTitle&&(route.path=='/'||route.path=='/home')?'60px':'0'}">
  <!-- ||route.path=="/about" -->
    <div class="head_box" :style='{"background":(route.path=="/academy"||route.path=="/"||route.path=="/home")?"transparent":"#FF2944"}'>
      <div class="box flex_between">
        <div class="logo_text">
          <a href="https://www.kittyhow.com/"><img width="180" height="auto" src="@/assets/logo.png"></a>
        </div>
        <div class="flex_start">
          <ul id="nav" class="flex_end top_nav">
            <li class="nav_detail" :class="data.tabIndex!==1&&(route.path=='/'||route.path=='/home')?'current':''">
              <router-link @click="changeTabs(0)" to="/">首页
                <div></div>
              </router-link>
            </li>
            <li class="nav_detail" :class="data.tabIndex==1?'current':''" style="position:relative">
              <span  @click="changeTabs(1)" >产品服务 <div></div></span>
              <productServe  :top="data.warnTitle?'100px':'60px'" @closeProduct="data.tabIndex=''" v-if="data.tabIndex==1"></productServe>
            </li>
            <li class="nav_detail" :class="data.tabIndex!==1&&route.path=='/price'?'current':''" id="download">
              <router-link to="/price" @click="changeTabs(0)" target="_black">资费<div></div></router-link>
            </li>
            <li class="nav_detail">
              <a href="https://kittyhow.com/login/#/tutorial/index" target="_black">帮助中心</a>
            </li>
            <li class="nav_detail" :class="data.tabIndex!==1&&route.path=='/about'?'current':''">
              <router-link to="/about" @click="changeTabs(0)" target="_black">关于我们<div></div></router-link>
            </li>
            <li class="login login1">
            <router-link to="/study"  target="_black">学员看课</router-link>
              <!-- <a href="https://kittyhow.com/study" target="_black"></a> -->
            </li>
            <li class="login">
              <a href="https://kittyhow.com/login" target="_black">老师登录</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="data.tabIndex==0&&(route.path=='/'||route.path=='/home')" class="box" style="display: flex; justify-content: flex-end">
      <div style="width: 640px; margin-top: 220px">
        <h1 class="wow fadeInUpBig" style="font-size: 60px; visibility: visible; font-family: SourceHanSansSC-bold;">知视猫</h1>
        <p class="wow fadeInUpBig" style="font-size: 18px; font-weight: normal; visibility: visible; font-family:SourceHanSansSC-light">
          服务有趣的知识灵魂
        </p>
        <div style="display: flex; justify-content: flex-end">
          <div @click="$emit('showModal')" id="banner_use_btn" href="javascript:;" target="_blank" class="btn wow fadeInUpBig" style="visibility: visible; ">
            <span>立即入驻</span>
          </div>
          <div class="code_small btn wow fadeInUpBig flex flex-center " style="width: 50px; visibility: visible; ">
            <img style="width: 22px; height: 22px;margin:auto" src="@/assets/stLine-qrcode-l@3x.png">
            <div class="code_wrap">
              <div class="code_box">
                <div>
                  <img alt="" src="@/assets/ercode.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="head_fixed wow animated" style="top: 0px; visibility: visible; ">
    <div class="box flex_between">
      <div class="logo_text">
        <img src="https://file.jinglinshe.com/kittyhow/logo.png" />
      </div>
      <div class="flex_start">
        <ul id="nav" class="fixed_nav flex_end">
          <!-- <div class="underline" style="left: 0px; width: 46px;"></div> -->
          <li :class="data.tabIndex!==1&&(route.path=='/'||route.path=='/home')?'current':''">
            <router-link @click="changeTabs(0)" to="/">首页
              <div></div>
            </router-link>
          </li>
          <li :class="data.tabIndex==1?'current':''" style="position:relative" @mouseenter="data.tabIndex==1">
            <span @click="changeTabs(1)" >产品服务 <div></div></span>
            <productServe @closeProduct="data.tabIndex=''"  :top="data.warnTitle?'90px':'60px'"  v-if="data.tabIndex==1"></productServe>
          </li>
          <li :class="data.tabIndex!==1&&route.path=='/price'?'current':''" id="download">
            <router-link to="/price" @click="changeTabs(0)">资费<div></div></router-link>
          </li>
          <li :class="data.tabIndex==3?'current':''">
            <a href="https://kittyhow.com/login/#/tutorial/index" target="_black">帮助中心</a>
          </li>
          <li :class="data.tabIndex!==1&&route.path=='/about'?'current':''">
            <router-link to="/about" @click="changeTabs(0)" target="_black">关于我们<div></div></router-link>
          </li>
          <li class="login login1">
          <router-link to="/study" target="_black">学员看课</router-link>
            <!-- <a href="https://kittyhow.com/study" target="_black">学员看课</a> -->
          </li>
          <li class="login">
            <a href="https://kittyhow.com/login" target="_black">老师登录</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
  import {
    useRoute
  } from 'vue-router'
  import {
    onMounted,
    onUnmounted,
    reactive,
    watch
  } from 'vue'
  import productServe from '@/components/productServe.vue';
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  const data = reactive({
    showProduct: false, //产品服务显示
    warnTitle: 1, //公告配置文字
    tabIndex: 0, //导航变动
  });
  // 路由
  const route = useRoute();
  watch(() => route.path, (newRoute, oldRoute) => {
    data.tabIndex = 0;
  });
  // 新版官网---- 公告文字配置
  const getConfig = async() => {
    try {
      const response = await $axios.post('/zsm/api/setting/config?key=zsm_web_warn_title');
      data.warnTitle = response.data.data || '';
    } catch (error) {
      data.warnTitle = ''
      console.error('请求失败:', error)
    }
  }
  //修改导航状态
  const changeTabs = async(index) => {
    if (data.tabIndex == index) {
      data.tabIndex = 0;
    } else {
      data.tabIndex = index;
    }
  }
  const scrolling = () => {
    const element = document.querySelector(".head_fixed");
    const head_box = document.querySelector(".box");
    // 滚动条距文档顶部的距离
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    // 判断是否到了最顶部
    if (scrollTop <= 90) {
      element.style.opacity = 0;
      element.style.transition = 'opacity 0.5s ease-in-out';
      head_box.style.opacity = 1;
      data.tabIndex = 0;
    } else {
      element.style.opacity = 1;
      element.style.transition = 'opacity 0.5s ease-in-out';
      head_box.style.opacity = 0;
      data.tabIndex = 0;
    }
  };
  onMounted(() => {
    console.log(route.path, 'header==========');
    if (route.path == '/about') {
      data.tabIndex = 4;
    }
    // 获取配置文字
    getConfig()
    window.addEventListener("scroll", scrolling);
  })
  onUnmounted(() => {
    window.removeEventListener("scroll", scrolling);
  })
</script>
<style lang="scss" scoped>
  @import '@/styles/head.scss';
</style>