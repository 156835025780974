<template>
  <div class="coursec-info flex">
    <div class="course-left">
      <userDetailInfo :userInfo="userInfo"></userDetailInfo>
      <courseChapter @prepayAlipay="prepayAlipay" :zsmAppGoodDetailList="zsmAppGoodDetailList" :chooseCourseDetailId="chooseCourseDetailId" @chooseCourseDetails="chooseCourseDetails" :payProgress="payProgress" :courseInfo="courseInfo"></courseChapter>
    </div>
    <div class="course-right">
      <courseDesc :chooseCourseDetail="chooseCourseDetail" :chooseCourseDetailId="chooseCourseDetailId" :payProgress="payProgress" :successRateVal="successRateVal" @changeRateValueVisible="changeRateValueVisible" :courseInfo="courseInfo" />
      <!-- <router-view :chooseCourseDetail="chooseCourseDetail" :chooseCourseDetailId="chooseCourseDetailId" :payProgress="payProgress" :successRateVal="successRateVal" @changeRateValueVisible="changeRateValueVisible" :goodInfo="goodInfo" /> -->
    </div>
    <el-dialog class="rateValueVisible" :visible.sync="rateValueVisible" style="margin-top: calc(100vh - 360px);" :modal="false">
      <div>
        <div class="rateContent">
          <div class="rateItem">
            <p>请对课程做出评价</p>
            <div class="rateInfo">
              <el-rate :texts="['非常不满意', '不满意', '一般', '满意', '非常满意']" v-model="rateValue" void-color="#f5f5f5" :void-icon-class="iconClasses" text-color="#999" show-text>
              </el-rate>
            </div>
          </div>
          <div class="rateItem">
            <p>您对该课程有哪些补充建议？</p>
            <div class="rateInfo">
              <el-input type="textarea" placeholder="请输入内容" v-model="remarks">
              </el-input>
            </div>
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="saveRateInfo">提 交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="rateValueVisible2" :visible.sync="feedbackVisible" style="width:228px!important;height: 100px!important;;margin-top: calc(100vh - 360px);" :modal="false">
      <div class="feedback" style="height: 100px;">
        <img src="https://lf1-cdn-tos.bytescm.com/obj/cc-static/thankyou.gif" alt="知视猫">
        <p>感谢您的反馈</p>
      </div>
    </el-dialog>
    <el-dialog class="formData" title="提示" :visible.sync="dialogVisible" width="500px">
      <div>
        <el-form>
          <el-form-item label="账号">
            <el-input v-model="form.mobile" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.pwd" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleAccount">确 定</el-button>
            </span>
    </el-dialog>
    <!--
       <div  class="feedback" style="opacity: 0;" >
                  <div ref="alipayWap" v-html="applyForm" />
        </div> -->
  </div>
</template>
<script>
  import BASE_URL from '@/utils/BASE_URL';
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  import courseDesc from "./desc.vue"
  import userDetailInfo from "./components/user-info.vue"
  import courseChapter from "./components/chapter.vue"
  export default {
    components: {
      userDetailInfo,
      courseChapter,
      courseDesc
    },
    data() {
      return {
        form: {
          mobile: '', //账号
          pwd: '' //密码
        },
        dialogVisible: false, //账号密码
        applyForm: '', //回调的html
        alipay: false, //支付宝支付弹框
        zsmAppGoodDetailList: [], //知视猫 详情列表
        successRateVal: 0, //完成评价
        remarks: '', //介意
        iconClasses: ['el-icon-star-on', 'el-icon-star-on', 'el-icon-star-on', 'el-icon-star-on', 'el-icon-star-on'],
        rateValueVisible: false, //评分是否展示
        feedbackVisible: false, //评分是否展示
        rateValue: '', //评分
        chooseCourseDetail: '', //课程详情
        chooseCourseDetailId: '', //当前点击的课程详情
        payProgress: 0, //当前产品是否购买
        userInfo: '', //用户信息
        goodInfo: '',
        courseInfo:{}, //商品信息 
      }
    },
    mounted() {
      if (this.$route.query.id) {
        this.getAlipay(this.$route.query.id)
      } else {
        this.$router.replace('/course')
      }
    },
    methods: {
      /**
       * 获取单个产品
       * @param {*} id
       */
      async getAlipay(id) {
        const response = await $axios.get(BASE_URL.ZSM_BASE_GOOD + `/zsm/api/alipay/get?id=${id}`);
        if (response) {
          this.form.goodId = response.data.id
          this.goodInfo = response.data;
          this.getGood(response.data.remarks)
        }
      },
      //支付
      prepayAlipay() {
          this.$router.push('/pay/payment?id=' + this.goodInfo.id)
      },
      // 保存评分
      saveRateInfo() {
        this.saveScore()
      },
      // 获取单个产品
      async getGood(id) {
        const response = await $axios.get(BASE_URL.ZSM_BASE_GOOD + `/zsm/api/good/getFormCache?id=${id}`);
        let courseInfo = response.data.data;
        if (courseInfo.mixContent) {
          this.mixContentInfo2 = courseInfo.mixContent
          var re3 = new RegExp('"', 'g')
          var re2 = new RegExp('＞', 'g')
          var re1 = new RegExp('＜', 'g')
          var re4 = new RegExp('＂', 'g')
          var msg = courseInfo.mixContent.replace(re1, '<') // 执行替换成空字符
          var msg2 = msg.replace(re2, '>') // 执行替换成空字符
          var msg3 = msg2.replace(re4, '"') // 执行替换成空字符
          courseInfo.mixContent = msg3 
        }
        this.courseInfo = courseInfo;
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
   section{
    width:100%!important;
  }
  .formData::v-deep {
    input {
      height: 44px;
      line-height: 44px;
    }
  }
  .coursec-info {
    width: 100%;
    margin: 0 auto;
    .rateValueVisible::v-deep {
      // margin-top: 30px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__footer {
        padding-bottom: 0;
        .dialog-footer {}
      }
      .footer {
        display: flex;
        justify-content: flex-end;
        button {
          padding: 10px 10px;
          height:70px;
          min-width: 90px;
          border-color: #2f88ff !important;
          background-color: #2f88ff !important;
        }
      }
      .feedback {
        text-align: center;
        img {
          width: 150px;
        }
        p {
          text-align: center;
          color: #333;
          font-family: PingFangSC-Semibold, sans-serif;
          font-weight: bold;
        }
      }
      .el-dialog {
        margin-top: calc(100vh - 360px) !important; // margin-top: 10px !important;
        position: fixed;
        bottom: 50px;
        max-width: 400px; // max-height: 320px;
        box-sizing: border-box;
        background: #fff;
        background: #fff;
        box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
        border-radius: 8px;
        padding: 32px 0 24px;
        overflow: visible;
        margin-right: 0;
        top: 0;
        right: 32px;
        .el-icon-close {
          font-size: 18px;
        }
        .el-dialog__body {
          padding-top: 0;
          padding-bottom: 0;
        }
        .rateContent {
          margin-top: -30px;
          .rateItem {
            &:nth-of-type(2) {
              height: 140px;
            }
            color: #333;
            font-family: PingFangSC-Semibold,
            sans-serif;
            font-weight: bold;
            &>p::before {
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0;
              background: #f45858;
              border-radius: 50%;
              content: "";
              margin-right: 4px;
            }
            .rateInfo {
              height: 55px;
              .el-rate {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
              }
              textarea {
                height: 80px;
                width: 100%;
                resize: none;
              }
              .el-rate__icon {
                font-size: 36px;
                height: 42px;
              }
              .el-rate__text {
                width: 400px;
                font-size: 12px;
                font-weight: normal;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .rateValueVisible2::v-deep {
      @extend .rateValueVisible;
      .el-dialog {
        margin-top: calc(100vh - 260px) !important;
        width: 340px;
        height: 240px;
      }
    }
    .course-left {
      width: 420px;
      height: 100vh; 
      margin-right: 8px;
      overflow: hidden;
      overflow-y: auto;
    }
    .course-right {
      flex: 1;
    }
  }
</style>
