<template>
  <div class="payment">
    <div class="courseInfoL pointer" @click="$router.replace('/')">苏州无用科技有限公司</div>
    <div class="payment-con flex">
      <div class="payment-conL">
        <img :src="goodInfo.cover" alt="">
        <div class="title" style="margin-top: 15px;">{{goodInfo.title}}</div>
        <div class="payment-price"> <span style="font-size: 16px;color: #000000;">支付金额：</span> ￥{{goodInfo.price}}</div>
      </div>
      <div class="payment-conR" style="margin-left: 60px;">
        <el-form label-position="top" ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.receiverName"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.receiverMobile"></el-input>
          </el-form-item>
          <el-form-item label="收货地址">
            <el-input type="textarea" v-model="form.receiverAddress"></el-input>
          </el-form-item>
          <el-form-item style="margin-top:50px;">
            <div clas="flex flex_between">
              <el-button type="primary" @click="prepayAlipay">
               <img src="https://file.jinglinshe.com/kittyhow/%E6%94%AF%E4%BB%98%E5%AE%9D%E6%94%AF%E4%BB%98.png" />支付宝支付
              </el-button>
              <el-button type="primary" @click="prepayAlipay">
                 <img src="https://file.jinglinshe.com/kittyhow/%E5%BE%AE%E4%BF%A1%E6%94%AF%E4%BB%98.png" />  微信支付
              </el-button>
              <el-button @click="$router.back()">取消</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="feedback" style="opacity: 0;">
      <div ref="alipayWap" v-html="applyForm" />
    </div>
  </div>
</template>

<script>
  import BASE_URL from '@/utils/BASE_URL';
  import {
    ElMessage
  }
  from 'element-plus'
  import {
    $axios
  } from '@/utils/request'; // 从 request.js 中导入 $axios
  export default {
    data() {
      return {
        applyForm: '', //回调的html
        goodInfo: '', //课程信息
        form: {
          receiverName: '',
          receiverMobile: '',
          receiverAddress: '',
          quantity: 1,
          goodId: '',
          returnUrl: 'http://kittyhow.com/'
        }
      }
    },
    mounted() {
      if (this.$route.query.id) {
        this.getGood(this.$route.query.id)
      } else {
        this.$router.replace('/pay')
      }
    },
    methods: {
      // 微信支付
      async wechatPay() {
        ElMessage({
          customClass: 'ele',
          message: '暂未开放',
          type: 'error',
        })
      },
      // 支付宝支付
      async prepayAlipay() {
        ElMessage({
          customClass: 'ele',
          message: '暂未开放',
          type: 'error',
        })
        // let that = this
        // const response = await $axios.post(`/zsm/api/alipay/prepay`, this.form);
        // this.$nextTick(() => {
        //   // 提交支付表单
        //   this.applyForm =
        //     `<form name="submit_form" method="post" action="${response.data}">
        //                       <input type="submit" value="提交" style="display:none">
        //                       </form> `
        //   setTimeout(() => {
        //     that.$refs.alipayWap.children[0].submit();
        //   }, 50)
        // });
      },
      /**
       * 获取单个产品
       * @param {*} id
       */
      async getGood(id) {
        const response = await $axios.get(BASE_URL.ZSM_BASE_GOOD + `/zsm/api/alipay/get?id=${id}`);
        if (response) {
          this.form.goodId = response.data.id
          this.goodInfo = response.data;
        }
      },
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .payment {
    button {
      height: 42px;
      width: 140px;
      font-family: SourceHanSansSC-bold;
      font-size: 14px;
      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
      &:nth-of-type(1) {
         background-color: #009fe8;
        border-color: #009fe8;
      }
      &:nth-of-type(2) {
        background-color: #13cc0a;
        border-color: #13cc0a;
       
      }
    }
    .courseInfoL {
      padding: 0 10px;
      height: 64px;
      line-height: 64px;
      background-color: #fff;
      font-size: 22px;
      font-family: SourceHanSansSC-bold;
      margin-bottom: 10px;
    }
    .payment-con {
      padding: 40px;
      background-color: #fff;
      width: 1180px;
      border: 3px solid #f8f8f8;
      margin: 0 auto;
      .payment-conL {
        width: 417px;
        height: 256px;
        img {
          width: 417px;
          height: 256px;
          border-radius: 6px;
        }
      }
      form {
        width: 500px;
      }
      .title {
        text-align: left;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: rgb(24, 24, 24);
        letter-spacing: 0px;
        line-height: 32px;
        width: 209px;
        height: 32px;
        margin-top: 10px;
      }
      .payment-price {
        padding: 25px 0px;
        line-height: 24px;
        font-family: PingFangSC-Semibold;
        font-size: 26px;
        color: #ff4d6d !important;
        letter-spacing: 0.41px;
        text-align: left;
        margin-right: 12px;
      }
      .payment-conR {
        flex: 1;
      }
    }
  }
</style>
