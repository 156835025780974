<template>
  <div class="courseChapter">
    <div class="courseInfo">
      <img :src="courseInfo.cover" width="340" height="auto" class="base-image">
      <div class="couseDetail" >
        <div class="title flex">
          <div class="title-detail">{{courseInfo.title}}</div>
          <div class="price" v-if="courseInfo.price">￥{{courseInfo.price}}</div>
          <div class="price" v-else>￥0</div>
        </div>
        <div class="desc flex">
          <span v-if="payProgress==1">已购买</span>
          <span v-else>未购买</span>
          <el-button type="primary" v-if="payProgress==1">立即学习</el-button>
          <el-button type="primary" v-else @click="$emit('prepayAlipay')">立即购买</el-button>
        </div>
      </div>
    </div>
<!-- 每次开直播的时候都要申请，还是说可以一直使用 -->
    <div class="detail">
      <div class="chapter-title active">
        精品课简介
    </div>
      <!-- <router-link @click.native="$emit('chooseCourseDetails','')" class="chapter-title" :class="chooseCourseDetailId==''?'active':''" to="/course-c/course-detail"> 精品课简介 </router-link> -->
      <!-- <p>课程目录</p> -->
      <div class="chapter">
        <!-- <el-collapse v-model="activeNames" >
          <el-collapse-item :title="title" name="1" >
            <div :class="chooseCourseDetailId==item.id?'active':''" @click.stop="watchGoodDetail(item)" class="flex" v-for="item of zsmAppGoodDetailList">
              <div class="index_box_hALSe"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle v-if="chooseCourseDetailId==item.id" cx="6" cy="6" r="5.5" stroke="#ffffff"></circle>
                  <circle v-else cx="6" cy="6" r="5.5" stroke="#338AFF"></circle>
                </svg></div>
              <div class="lesson-type-box">
                <i class=" el-icon-video-camera-solid"></i>
              </div>
              <div class="course-name"> {{item.title}} </div>
            </div>
          </el-collapse-item>
        </el-collapse> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:['courseInfo','payProgress','chooseCourseDetailId','zsmAppGoodDetailList','zsmAppGoodDetailList'],
    data() {
      return {
        title:'',//课程标题
        activeNames: ''
      }
    },
    watch:{
      goodInfo(){
        if(this.courseInfo.title.length>12){
            this.title=this.courseInfo.title.slice(0,12) + '...'
        }else{
          this.title=this.courseInfo.title
        }
      }
    },
    mounted() {
        // if(this.courseInfo){
        //   if(this.courseInfo.title.length>12){
        //       this.title=this.courseInfo.title.slice(0,12) + '...'
        //   }else{
        //     this.title=this.courseInfo.title
        //   }
        // }
    },
    methods:{
      // 观看
      watchGoodDetail(detail){
          if(this.payProgress==0){
              this.$message.error('请支付后再行观看');
                return false
            }
            this.$emit('chooseCourseDetails',detail)
            this.$router.push({name:'courseCWatch',params:{detail,goodInfo:this.goodInfo}})
      }
    }
  }
</script>

<style lang="scss" scoped="scoped">
 section{
    width:100%!important;
  }
  .detail ::v-deep {
    padding: 20px 32px;
     .router-link-active{
       background: #2180ff;
       color: #fff;
     }
    .active{
      background: #2180ff!important;
      color: #fff!important;
      .el-icon-video-camera-solid{
        color: #fff!important;
      }
    }

    .el-collapse {
      svg {
        width: 20px;
      }

      .el-icon-video-camera-solid {
        font-size: 20px;
        margin: 0 10px;
        color: #007aff;
      }

      .course-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-collapse-item__header {
        padding-left: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        height: 46px;
        padding-top:20upx;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }

      .el-collapse-item__content {
        padding-bottom: 0;

        &>div {
          height: 48px;
          margin: 4px 0;
          align-items: center;
          padding: 0 16px;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            background: #f5f5f5;
          }
        }

      }
    }

    &>p {
      margin-bottom: 4px;
      padding-left: 8px;
      color: #999;
      font-size: 12px;
      line-height: 20px;
    }

    .chapter-title {
      display: block;
      margin-bottom: 8px;
      padding-left: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 48px;
      border-radius: 4px;
      cursor: pointer;
      &:hover{
        background: #f5f5f5;
      }
      &.active{
        background: #2180ff;
        color: #fff;
      }
    }
  }

  .courseChapter {
    height: calc(100vh - 64px);
    overflow-y: auto;
    .courseInfo {
      padding: 24px 40px 20px;
      border-bottom: 1px solid #ebebeb;
      .chapter {}

      .couseDetail {
        .title {
          margin: 16px 0 12px;
          color: #333;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          .price{
            font-size: 16px;
            color: #f00;
          }
          .title-detail{
            width: 350px;
            padding-right: 15px;
          }
        }

        .desc {
          justify-content: space-between;
          align-items: center;
          span {
            color: #666;
            font-size: 12px;
            line-height: 18px;
          }
          button {
            background-color: #338aff !important;
            border-color: #338aff !important;
            width: 100px;
            height:38px;
          }
        }
      }

      img {
        object-position:top;
        vertical-align: top;
        object-fit: cover;
        border-radius: 4px;
        width: 340px;
        height: 192px;
      }
    }
  }
</style>
