<template>
    <div class=" descIndex">
        <div class="top">
            <div class="left">
                <div class="text40">你需要挂载</div>
                <div class="text40">哪个销售端口？</div>
                <p class="text14 desc">三端知识变现小程序支持抖音、快手、微信，多端生态链接流量快速搭建知识变现链路闭环</p>
            </div>
            <img src="https://file.jinglinshe.com/kittyhow/橙黄色可爱卡通字体组合微信公众号封面 (4).jpg" />
        </div>
        <div class="descCon">
            <div>
                <div class="detailItem  " v-for="(item,index) of data.con.contentList">
                    <div class="flex_around">
                        <div class="detailLeft" v-if="item.position=='left'">
                            <div class="tit">
                                <div class="text18 flex-center">
                                    <div class="pointer" :class="item.choose==detailIndex?'red':''" v-for="(detailItem,detailIndex) of item.tit">
                                        {{detailItem}}
                                    </div>
                                </div>
                            </div>
                            <div class="desc-list">
                                <div class="text36 red" v-if="item.styles==1">{{item.descList[item.choose][0]}}</div>
                                <div class="text36">{{item.descList[item.choose][1]}}</div>
                                <div class="subtit text15">{{item.descList[item.choose][2]}}</div>
                                <div class="subtit text15" v-if="item.descList[item.choose][3]">{{item.descList[item.choose][3]}}</div>
                                <button class="btn button-reset" @click="$emit('showModal')">立即体验</button>
                            </div>
                        </div>
                        <div class="detailRight" v-if="item.position=='left'">
                            <img :src="item.descImg[item.choose]" />
                        </div>
                        <div class="detailRight" v-if="item.position=='right'">
                            <img :src="item.descImg[item.choose]" />
                        </div>
                        <div class="detailLeft" v-if="item.position=='right'">
                            <div class="tit">
                                <div class="text18 flex-center">
                                    <div class="pointer" :class="item.choose==detailIndex?'red':''" v-for="(detailItem,detailIndex) of item.tit">
                                        {{detailItem}}
                                    </div>
                                </div>
                            </div>
                            <div class="desc-list">
                                <div class="text36 red">{{item.descList[item.choose][0]}}</div>
                                <div class="text36">{{item.descList[item.choose][1]}}</div>
                                <div class="subtit text15">{{item.descList[item.choose][2]}}</div>
                                <div class="subtit text15" v-if="item.descList[item.choose][3]">{{item.descList[item.choose][3]}}</div>
                                <button class="btn button-reset" @click="$emit('showModal')">{{item.btn}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_block block_3" style="padding-bottom:0">
            <div class="main_title">
                <div class="main_font">知视猫 · 入驻流程</div>
            </div>
            <div class="feature_list feature_list2" style="border: 1px solid rgba(69, 71, 77, 0.08);">
                <div class="feature_pannel" style="padding:43px 15px;border:none">
                    <img src="https://file.jinglinshe.com/kittyhow/微信截图_20231201002256.png">
                    <div class="text16" style="margin: 10px 10px 12px 0;">
                        1. 提交注册信息
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        在抖音、快手或微信搜索知视猫小程序，用户中心申请入驻
                    </div>
                </div>
                <div class="feature_pannel" style="padding:50px 15px;border:none">
                    <img src="https://file.jinglinshe.com/kittyhow/微信截图_20231201002224.png">
                    <div class="text16" style="margin: 10px 10px 12px 0;">
                        2. 创建课程
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        在知视猫电脑端登录上传课程也支持手机端上传课程
                    </div>
                </div>
                <div class="feature_pannel" style="padding:50px 15px;border:none">
                    <img src="https://file.jinglinshe.com/kittyhow/微信截图_20231201002343.png">
                    <div class="text16" style="margin: 10px 10px 12px 0;">
                        3. 课程审核
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        课程通过知视猫一审和平台二次审核通过后可以上架
                    </div>
                </div>
                <div class="feature_pannel" style="padding:50px 15px;border:none">
                    <img src="https://file.jinglinshe.com/kittyhow/微信截图_20231201002413.png">
                    <div class="text16" style="margin: 10px 10px 12px 0;">
                        4. 挂载能力授权
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        课程通过审核后账号需要获取挂载能力的授权
                    </div>
                </div>
                <div class="feature_pannel" style="padding:50px 15px;border:none">
                    <img src="https://file.jinglinshe.com/kittyhow/微信截图_20231201002458.png">
                    <div class="text16" style="margin: 10px 10px 12px 0;">
                        5. 挂载销售课程
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        短视频和直播进行挂载课程开始知识变现之旅
                    </div>
                </div>
            </div>
        </div>
        <div class="content_block block_3 block_5" style="padding:12px 0 0 0 ">
            <div class="feature_list">
                <div class="main_bg" :style='{"background-image":"url(https://file.jinglinshe.com/kittyhow/未命名的设计.jpg)"}'>
                    <div class="font_bold_16" style="margin-bottom: 14px">
                        入驻扶持
                    </div>
                    <div style="font-size: 18px">Support</div>
                </div>
                <div class="feature_pannel" style="padding:26px 15px;" v-for="item of data.con.enrollDesc.desc.list">
                    <img :src="item.img">
                    <div class="text16" style="margin-bottom: 22px">
                        {{item.tit}}
                    </div>
                    <div style="display: flex;justify-content:center;font-size:13px;line-height:20px;color:#363636">
                        {{item.desc}}
                    </div>
                </div>
            </div>
        </div>
        <div class="sec2">
            <section>
                <h2 class="text36">看见知识、创造价值</h2>
                <div class="flex_between team-list">
                    <li class="team" v-for="item of data.shopList">
                        <img alt="知视猫" :src="item.img">
                        <div>
                            <div class="text14">{{item.tit}}</div>
                            <span class="text12">{{item.subTit}}</span>
                        </div>
                    </li>
                </div>
            </section>
            <div class="use_btn" @click="$emit('showModal')">立即加入</div>
        </div>
        <!-- <div class="sec2">
                <section>
                    <div class="main_title">
                        <div class="main_font">{{data.con.shopTit}}</div>
                    </div>
                    <div class="flex_between margin-t50">
                        <li class="team" v-for="item of data.shopList">
                            <img alt="知视猫" :src="item.bizCover">
                            <div>{{item.bizName}}</div>
                        </li>
                    </div>
                </section>
            </div> -->
        <!-- <div class="use_btn" @click="$emit('showModal')">立即加入</div> -->
        <!-- <div class="use_btn">立即使用</div> -->
    </div>
</template>
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
      import {
    shopList
  } from "@/utils/json"
    import {
        descPage
    } from "@/utils/json"
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    const data = reactive({
        ...descPage,
        chooseMarket: 1, //选中的优惠
        shopList
    });
    // 知视猫 · 营销管理 滑入事件监听
    function handleHoverMarket(index) {
        data.chooseMarket = index;
    }
    // 获取首页相关数据（用于合作老师渲染）
    // const shopHome = async() => {
    //     try {
    //         const res = await $axios.get('/zsm/api/om/listTopic?classifyCode=haoke&appFlag=1');
    //         res.data.map((item) => {
    //             if (item.rowNum == 3) {
    //                 data.shopList = item.omTopicBizList;
    //             }
    //         })
    //     } catch (error) {
    //         console.error('请求失败:', error)
    //     }
    // }
    onMounted(async() => {
        // shopHome()
    })
</script>
<style lang="scss" scoped>
    @import '@/styles/desc.scss';
</style>