<template>
  <div class="course-desc">
    <div class="rightCardHeader flex">
      <span>精品课简介</span>
      <div class="course-alert flex">
        <span data-v-6d3665dc="" class="course-alert-icon i-icon i-icon-tips"><svg width="16" height="16" viewBox="0 0 48 48"
              fill="none">
              <path d="M40 20C40 26.8077 35.7484 32.6224 29.7555 34.9336H24H18.2445C12.2516 32.6224 8 26.8077 8 20C8 11.1634 15.1634 4 24 4C32.8366 4 40 11.1634 40 20Z"
                fill="none" stroke="#999" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M29.7555 34.9336L29.0764 43.083C29.0332 43.6013 28.5999 44 28.0798 44H19.9201C19.4 44 18.9668 43.6013 18.9236 43.083L18.2444 34.9336"
                stroke="#999" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M18 17V23L24 20L30 23V17" stroke="#999" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg></span>
        <span>课程中涉及的产品介绍仅供参考，具体内容以相关平台展示为准</span>
      </div>
    </div>
    <div class="intro">
      <div v-if="courseInfo.mixContent" v-html="courseInfo.mixContent">
      </div>
      <div v-else>
        <img v-for="item of courseInfo.detailImages" :src="item" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['courseInfo'],
    data() {
      return {
      }
    },
    computed: {
    },
    mounted() {
      console.log(this.courseInfo)
    }
  }
</script>

<style lang="scss" scoped="scoped">
 
  .course-desc::v-deep {
    background-color: #fff;
     section{
    width:100%!important;
  }
    .intro {
      flex: 1;
      &>div {
        font-size: 0;
        zoom: 1;
        overflow: hidden;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
    .rightCardHeader {
      width: 100%;
      height: 84px;
      padding-left: 12px;
      background: #fff;
      border-bottom: 1px solid #ebebeb;
      align-items: center;
      &>span {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
      }
      .course-alert-icon {
        margin-right: 4px;
      }
      .course-alert {
        flex: 1 0 auto;
        margin-left: 8px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 8px;
        background: #ebf3ff;
        border-radius: 4px;
        height: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        line-height: 26px;
        span {
          display: block;
        }
        .course-alert-icon {
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>
