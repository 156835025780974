<template>
  <div class="courseInfo flex">

    <div class="courseInfoL pointer" @click="$router.back()">   <i class="el-icon-arrow-left"></i> 苏州无用科技有限公司</div>
  <!--  <router-link to="/home" class="courseInfoL">
      <img src="https://lf3-cdn-tos.bytegoofy.com/goofy/academy/web/img/logo.de99765a.svg" alt="巨量学">
    </router-link> -->

   <!-- <div class="courseInfoR flex" v-if="userInfo!==''">
      <router-link to="/user"> 个人中心 </router-link>
      <div class="delimiter"></div>
      <div class="userInfo pointer">
        <el-dropdown @visible-change="changeVisible" icon="el-icon--right">
          <span class="el-dropdown-link flex">
            <img src="https://lf3-cdn-tos.bytegoofy.com/goofy/academy/web/img/avatar.30eacde6.svg" alt="头像" class="avatar">
            <i v-if="dropdownShow==false" class="el-icon-caret-bottom caret-bottom el-icon--right"></i>
            <i v-else class=" el-icon-caret-top el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item> <div @click.stop="goAvatarHome">返回投放管理平台</div> </el-dropdown-item>
            <el-dropdown-item> <div @click.stop="loginOut">退出</div> </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>


    <div class="courseInfoR flex" v-else>
      <div class="delimiter"></div>
      <router-link to="/login" style="padding-left: 12px;"> 登录 </router-link>
    </div> -->
  </div>
</template>

<script>

  export default {
    data() {
      return {
        dropdownShow:false,//展示隐藏
        userInfo: ''
      }
    },
    mounted() {
      // this.getByMobile()
    },
    methods: {
      // 退出
      loginOut(){
        removeLocalStorage('zsm-userInfo')
        removeLocalStorage('zsm-login')
        removeLocalStorage('zsm-mobile')
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      },
      goAvatarHome(){
        this.$router.push('/home')
      },
      changeVisible(e){
        this.dropdownShow=e
      },
     /* // 通过手机号获取用户信息
      async getByMobile() {
        const userInfo = await getByMobile(this.$store.state['zsm-user'].userMobile)
        userInfo.data.map((item, index) => {
          if (item.platformDict == this.$store.state['zsm-user'].platformCode) {
            this.userInfo = item
          }
        })
      }, */
    }
  }
</script>
<style lang="scss">
  .el-dropdown-menu {
    border: none;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    .popper__arrow{
      display: none;
    }
   li {
      display: block;
      padding: 0 24px;
      height: 54px;
      font-size: 14px;
      line-height: 54px!important;
      &:hover {
        background-color: #f0f0f0!important;
      }
      // .el-icon-caret-top{
      //   -webkit-transition: -webkit-transform 0.5s ease;
      //    transform:rotate(180deg) ;
      // }
    }
  }
</style>
<style lang="scss" scoped="scoped">
  .courseInfo::v-deep {
    width: 450px;
    width: 100%;
    // height: 64px;
    padding: 25px 18px 0 40px;
    justify-content: space-between;
    align-items: center;




    .userInfo {
      align-items: center;
      padding-top: 5px;

      .avatar {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .caret-bottom,.el-icon-caret-top {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .courseInfoL {
      font-size: 20px;
      font-weight: bold;
      width: 320px;
      height: 24px;
      .el-icon-arrow-left{
        font-weight: bold;
        font-size: 20px;
      }
      img {
        width: 100%;
        height: 100%
      }
    }

    .courseInfoR {
      justify-content: flex;
      align-items: center;
      width: 260px;
      justify-content: flex-end;

      a {
        color: #333;
        font-size: 14px;
      }

      align-items: center;

      .delimiter {
        width: 1px;
        height: 25px;
        margin: 0 12px;
        background-color: #ebebeb;
      }
    }
  }
</style>
