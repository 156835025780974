// dev环境
const dev = {
  ZSM_BASE_API: 'https://joofish.com',
  // 用于icp备案链接测试环境时使用
  ZSM_BASE_GOOD: 'https://joofish.com'
  // ZSM_BASE_API: 'http://test.jinglinshe.com:9999',
  // ZSM_BASE_GOOD: 'http://test.jinglinshe.com:9999'
}
// prod环境
const prod = {
  ZSM_BASE_API: 'https://joofish.com',
  // 用于icp备案链接测试环境时使用
  ZSM_BASE_GOOD: 'https://joofish.com'
}
const BASE_URL = process.env.NODE_ENV === 'development' ? dev : prod
export default BASE_URL;
