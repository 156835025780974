<template>
    <div class=" descIndex" ref="descIndex">
        <div class="top">
            <div class="left" style="padding-top:50px;">
                <div class="text40">短视频和直播挂载</div>
                <!-- <div class="text40"></div> -->
                <p class="text14 desc" style="margin-top:25px;">通过短视频和直播间可以展示挂载课程的购买路径快速引流用户进行成交</p>
            </div>
            <img src="https://file.jinglinshe.com/kittyhow/挂载能力.jpg" />
        </div>
        <div class="navBar   ">
            <section>
                <div class="main_title ">
                    <div class="main_font">了解挂载能力</div>
                </div>
                <div class="flex_between">
                    <div class="navItem">
                        <img src="https://file.jinglinshe.com/kittyhow/挂载1.png" />
                        <div class="text20 navTit">一站式营销</div>
                        <div class="text14 sub">多种变现渠道解决流量问题提供一体化解决方案</div>
                    </div>
                    <div class="navItem">
                        <img src="https://file.jinglinshe.com/kittyhow/挂载2.png" />
                        <div class="text20 navTit">最短购买链路</div>
                        <div class="text14 sub">短视频/直播间可最短链路触达用户完成支付</div>
                    </div>
                    <div class="navItem">
                        <img src="https://file.jinglinshe.com/kittyhow/未命名的设计 (5).png" />
                        <div class="text20 navTit">海量用户群体</div>
                        <div class="text14 sub">获取短视频平台海量流量公域推流满足营销目标</div>
                    </div>
                    <div class="navItem">
                        <img src="https://file.jinglinshe.com/kittyhow/未命名的设计 (7).png" />
                        <div class="text20 navTit">服务体验</div>
                        <div class="text14 sub">摆脱传统知识普及模式提升用户互动与深度社群粘性</div>
                    </div>
                </div>
                <div class="use_btn " @click="$emit('showModal')">立即体验</div>
            </section>
        </div>
        <div class="live_content">
            <section>
                <div class="main_title ">
                    <div class="main_font text-left">知视猫 · IDEA方法论</div>
                </div>
                <div class="flex margin-t50">
                    <div class="ideaFun flex">
                        <div class="ideaFunL">
                            <img :src="data.ideaFun.list[data.ideaFun.index].lIcon" />
                            <div>
                                <ul class="flex_around">
                                    <li :class="data.ideaFun.index == ideaIndex ? 'active' : ''" @click="changeIdeaCurrent(ideaItem, ideaIndex)" class="pointer" v-for="(ideaItem, ideaIndex) of data.ideaFun.list">
                                        <span class="text20">{{ ideaItem.tit }}</span>
                                        <div class="text18">{{ ideaItem.sutT }}</div>
                                        <div class="line" v-if="data.ideaFun.index == ideaIndex"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="ideaFunR">
                            <div class="text24 flex_row_center">
                                <p></p>
                                {{ data.ideaFun.list[data.ideaFun.index].rCon.tit }}
                            </div>
                            <p class="text14">
                                <p v-for="ideaItemSub of data.ideaFun.list[data.ideaFun.index].rCon.subT">
                                    {{ ideaItemSub }}
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="live_content live_content2">
            <section>
                <div class="main_title ">
                    <div class="main_font text-left">{{ data.liveSwiperCon.tit }}</div>
                </div>
            </section>
            <swiper :autoplay="true" :modules="modules" :currentIndex="data.activeIndex + 1" @swiper="onSwiper" @slidePrevTransitionStart="slidePrevTransitionStart" @slideNextTransitionEnd="slideNextTransitionEnd" @slideChange="onSlideChange" @change="changeSwiper"
                :navigation="navigation" :options="swiperOptions" class="swiper " style="margin-top:95px" :slides-per-view="3" :space-between="50">
                <swiper-slide v-for="(item, index) of data.liveSwiperCon.list">
                    <div class="swiperItem2 ">
                        <div class="swiper_con flex align-center" v-if="data.activeIndex + 1 == index">
                            <div class="swiper-button-prev btn pointer prev" @click.stop="prevEl(item, index)" />
                            <div>
                                <div class="swiper_con_title text28">
                                    {{ item.tit }}
                                </div>
                                <div class="text18 swiper_con_subT">
                                    {{ item.subT }}
                                </div>
                            </div>
                            <div class="swiper-button-next btn pointer next" @click.stop="nextEl" />
                        </div>
                        <div class="swiper_left text20" v-else>
                            {{ item.tit }}
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <swiper :currentIndex="data.activeIndex" @swiper="onSwiper2" @slideChange="onSlideChange" @change="changeSwiper" :navigation="navigation" class="swiper " style="margin-top:36px" :slides-per-view="1" :options="swiperOptions" :space-between="50">
                <swiper-slide v-for="item of data.liveSwiperCon.list">
                    <div class="swiperItem flex-center flex">
                        <img class="img" :src="item.detailCon.img" alt="">
                        <div class="swiperItemR">
                            <span class="text24 detailConT">{{ item.detailCon.tit }}</span>
                            <ul>
                                <li class="text16" v-for="(itemSub, index) of item.detailCon.subT" :key="index">
                                    {{ itemSub }}
                                </li>
                            </ul>
                            <p class="text12 numList flex">
                                <div v-for="numItem of item.detailCon.numList">
                                    <div class="numItem">
                                        <p>
                                            <span class="text30 num">{{ numItem.num }}</span>
                                            <span class="text16">{{ numItem.percent }}</span>
                                        </p>
                                        <p class="subT text12">{{ numItem.subT }}</p>
                                    </div>
                                </div>
                            </p>
                            <div @click="journalismDetail(item.detailCon.id)" class="detailText flex text14 pointer flex-center">
                                {{ item.detailCon.detailText }}
                                <img src="@/assets/ze-arrow@3x.png" />
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="live_content " style="padding-bottom:150px">
            <section>
                <div class="main_title ">
                    <div class="main_font text-left">{{ data.playCon.tit }}</div>
                </div>
                <div class="flex margin-t50 playCon">
                    <div class="playConL">
                        <video v-if="data.playCon.list[data.playCon.current].childList[data.playCon.currentChild].img.indexOf('mp4')!=-1" loop="loop" autoplay="autoplay" :src="data.playCon.list[data.playCon.current].childList[data.playCon.currentChild].img" class="video-tvc childImg"
                            id="video-tvc" data-object-fit=""></video>
                        <img v-else class="childImg" :src="data.playCon.list[data.playCon.current].childList[data.playCon.currentChild].img" />
                    </div>
                    <div class="playConR">
                        <div class="playConR_tit">
                            <ul class="flex ">
                                <li @click="clearSetInterVal();setDataSetInterVal();data.playCon.current=index;data.playNav = index;data.playCon.currentChild=0" class="pointer" :class="data.playNav == index ? 'active' : ''" v-for="(item, index) of data.playCon.list">
                                    <span class="text22">{{ item.tit }}</span>
                                    <p class="text18">{{ item.subT }}</p>
                                    <div class="tags" v-if="data.playNav == index"></div>
                                </li>
                            </ul>
                            <div class="playConDetail pointer flex">
                                <div @click="clearSetInterVal();setDataSetInterVal();data.playCon.currentChild = index" :class="data.playCon.currentChild == index ? 'active' : ''" class="playConDetailC" v-for="(item, index) of data.playCon.list[data.playNav].childList">
                                    <span class="text22">{{ item.tit }}</span>
                                    <p class="text14" v-html="item.desc"></p>
                                    <!-- <p class="text14">{{ item.desc }}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="sec2 " style="margin-top:0;padding-top:0">
            <div class="shopInfo">
                <div class="shopTop">
                    <span class="text38">{{ data.shopInfo.tit }}</span>
                    <p class="text16">{{ data.shopInfo.subT }}</p>
                </div>
                <div class="numList flex_between flex">
                    <div v-for="item of data.shopInfo.numList">
                        <p class=" flex "> <span class="text40">{{ item.num }}</span>{{ item.per }} </p>
                        <div class="text16"> {{ item.subT }} </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="flex_between team-list " style="margin-top:90px">
                    <li class="team" v-for="item of data.shopList">
                        <img alt="知视猫" :src="item.img">
                        <div>
                            <div class="text14">{{item.tit}}</div>
                            <span class="text12">{{item.subTit}}</span>
                        </div>
                    </li>
                </div>
            </section>
            <div class="use_btn" @click="$emit('showModal')">立即加入</div>
        </div>
    </div>
</template>
<script setup>
    import {
        onMounted,
        onUnmounted,
        reactive,
        ref
    } from 'vue';
    import {
        dyLive,
        shopList
    } from "@/utils/json"
    import {
        $axios
    } from '@/utils/request'; // 从 request.js 中导入 $axios
    import {
        useRouter
    } from 'vue-router';
    import "swiper/css/navigation";
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue'; // swiper所需组件
    import {
        contextRegExp2
    } from "@/utils/index"
    import {
        Autoplay,
        Navigation,
        Pagination,
        A11y
    } from 'swiper/modules';
    import 'swiper/css'
    // 路由
    const modules = [Autoplay, Pagination, Navigation, A11y];
    const data = reactive({
        setInterVal: null, //定时器
        swiper1: null,
        swiper2: null,
        swiper_right: '', //左侧标题
        swiper_left: '', //左侧标题
        ...dyLive,
        activeIndex: 0, //当前滑动的内容
        shopList,
        scrollViewList: [], //元素内容
        // 多元化玩法
        playNav: 0
    });
    const router = useRouter();
    const navigation = ref({
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    });
    const swiperOptions = {
        autoplay: {
            delay: 100000,
            stopOnLastSlide: false,
            disableOnInteraction: false
        }
    };
    const prevEl = (item, index) => {
        onSlideChange({
            activeIndex: data.activeIndex - 1
        }) // 切换时触发
    };
    const nextEl = (item, index) => {
        onSlideChange({
            activeIndex: data.activeIndex + 1
        }) // 切换时触发
    };
    // 修改滑动 swiper1
    const onSwiper = (swiper) => {
        data.swiper1 = swiper;
    }
    // 修改滑动 swiper2
    const onSwiper2 = (swiper) => {
        data.swiper2 = swiper;
    }
    // 更改当前活动swiper
    const onSlideChange = (s) => {
        let list = data.liveSwiperCon.list;
        if (s.activeIndex >= list.length - 3) {
            data.activeIndex = 0;
            s.activeIndex = 0;
        } else {
            data.activeIndex = s.activeIndex;
        }
        if (s.activeIndex == 0) {
            data.swiper_left = data.liveSwiperCon.list[list.length - 2].tit
            data.swiper_right = data.liveSwiperCon.list[s.activeIndex + 1].tit;
        }
        data.swiper1.slideTo(s.activeIndex);
        data.swiper2.slideTo(s.activeIndex);
    };
    // 查看新闻详情
    const journalismDetail = async(id) => {
        router.push({
            path: '/journalism',
            query: {
                id
            }
        })
    }
    // 向前滑动
    const slidePrevTransitionStart = async(index) => {}
    // 向后滑动
    const slideNextTransitionEnd = async(index) => {}
    // 查看新闻页
    const qaListData = async() => {
        try {
            const response = await $axios.get('/zsm/api/qa/listData?qaDict=1001');
            let resList = response.data.list.slice(4, 7);
            resList.map((item, index) => {
                data.liveSwiperCon.list[index].detailCon.img = item.images
                data.liveSwiperCon.list[index].detailCon.tit = item.question
                data.liveSwiperCon.list[index].detailCon.id = item.id;
                data.liveSwiperCon.list[index].detailCon.desc = contextRegExp2(item.answer)
            });
            let list = data.liveSwiperCon.list;
            list.push(...list, ...list);
            data.swiper_left = list[0].tit;
            data.swiper_right = list[2].tit;
        } catch (error) {
            console.error('请求失败:', error)
        }
    }
    // 获取首页相关数据（用于合作老师渲染）
    // const shopHome = async() => {
    //     try {
    //         const res = await $axios.get('/zsm/api/om/listTopic?classifyCode=haoke&appFlag=1');
    //         res.data.map((item) => {
    //             if (item.rowNum == 3) {
    //                 data.shopList = item.omTopicBizList;
    //                 data.shopList.push(...data.shopList)
    //             }
    //         })
    //     } catch (error) {
    //         console.error('请求失败:', error)
    //     }
    // }
    // 知视猫 · IDEA方法论 更改
    const changeIdeaCurrent = async(item, index) => {
        data.ideaFun.index = index;
    }
    const clearSetInterVal = async(item, index) => {
        clearInterval(data.setInterval)
        data.setInterval = null;
    }
    // 设置定时器
    const setDataSetInterVal = async(item, index) => {
        data.setInterval = setInterval(() => {
            let index = data.playNav;
            let list = data.playCon.list;
            if (data.playCon.currentChild >= list[data.playNav].childList.length - 1) {
                if (index >= list.length - 1) {
                    index = 0;
                } else {
                    index++
                }
                data.playCon.current = data.playNav = index;
                data.playCon.currentChild = 0;
            } else {
                data.playCon.currentChild++
            }
        }, 22000);
    }
    const ideaFnSwiper = () => {
        setDataSetInterVal()
    }
    // swiper 变动
    const changeSwiper = async(item, index) => {}
    onMounted(async() => {
        // shopHome()
        qaListData()
        ideaFnSwiper()
    })
    onUnmounted(async() => {
        clearSetInterVal()
    })
</script>
<style lang="scss" scoped>
    @import '@/styles/desc.scss';
    @import '@/styles/live.scss';
</style>