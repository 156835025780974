import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '@/views/home/home.vue';
// 资费
import Price from '@/views/price/index.vue';
// 关于我们
import About from '@/views/about/index.vue';
// 新闻
import Journalism from '@/views/journalism/detail.vue';
// 说明
import Desc from '@/views/desc/index.vue';
// 多元营销
import Marketing from '@/views/marketing/index.vue';
// 知视学苑
import Academy from '@/views/academy/index.vue';
// 讲师后台
import Shop from '@/views/shop/index.vue';
// 直播挂载
import Live from '@/views/douyin/live.vue';
// 短视频挂载
import Mounts from '@/views/douyin/mounts.vue';
// 更多服务
import Serve from '@/views/serve/index.vue';
// 学员看课
import Study from '@/views/study/index.vue';
// 支付页
import Pay from '@/views/pay/index.vue';
// 支付详情页
import PayDetail from '@/views/pay/detail.vue';
// 支付观看
import PayList from '@/views/pay/list.vue';
// 支付页
import PayMent from '@/views/pay/payment.vue';



const routes = [
  {
    path: '/home',
    component: Home
  },
  {
    path: '/',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/price',
    component: Price,
  },
  {
    path: '/journalism',
    component: Journalism,
  }
  ,
  {
    path: '/desc',
    component: Desc,
  },
  {
    path: '/marketing',
    component: Marketing,
  },
  {
    path: '/academy',
    component: Academy

  },
  {
    path: '/shop',
    component: Shop,
  },
  {
    path: '/live',
    component: Live,
  },
  {
    path: '/mounts',
    component: Mounts,
  },
  {
    path: '/serve',
    component: Serve,
  },
  {
    path: '/study',
    component: Study,
  },
  {
    path: '/pay',
    component: Pay,
    redirect: '/pay/list',
    children: [
      {
        path: '/pay/list',
        component: PayList,
        name: 'payList'
      },
      {
        path: '/pay/detail',
        component: PayDetail,
        name: 'payDetail'
      },
      {
        path: '/pay/payment',
        component: PayMent,
        name: 'payPayment'
      }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const isPublic = to?.matched?.some(record => record?.meta?.public);
  const onlyWhenLoggedOut = to?.matched.some(
    record => record?.meta?.onlyWhenLoggedOut
  );
  if (!to.hash) {
    window.scrollTo(0, 0);
  }
  next();

});

export default router;