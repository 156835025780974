<template>
    <div class="priceIndex">
        <div class="price-con">
            <router-view></router-view>
        </div>
    </div>
</template>

 
<style lang="scss" scoped>
    @import '@/styles/study.scss';
    @import '@/styles/price.scss';
    .priceIndex{
        margin-bottom:60px;
    }
</style>