<template>
 <div class="mounts-index">
 
 </div>
</template>
 
<script setup >

</script>
 
<style scoped lang="scss">
 
</style>