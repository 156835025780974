<template>
    <div class="productBox flex" :style="{'top':top}">
        <div class="top"></div>
        <div class="left">
            <div class="tit flex-center">
                <div></div>知视猫服务</div>
            <div class="cont">
                <router-link @click.native="$emit('closeProduct')" to="/desc" tag="div" class="flex-center">
                    <img src="https://file.jinglinshe.com/kittyhow/知视猫3端小程序.png" alt="" srcset="">
                    <span>知视猫三端小程序</span>
                </router-link>
                <router-link @click.native="$emit('closeProduct')" to="/shop" tag="div" class="flex-center">
                    <!-- <a class="flex-center" target="_black" href="https://kittyhow.com/login/"> -->
                    <img src="https://file.jinglinshe.com/kittyhow/讲师后台.png" alt="" srcset="">
                    <span>讲师端后台</span>
                    <!-- </a> -->
                </router-link>
                <router-link @click.native="$emit('closeProduct')" to="/academy" tag="div pointer" class="flex-center">
                    <img src="https://file.jinglinshe.com/kittyhow/知视学苑.png" alt="" srcset="">
                    <span>知视学苑</span>
                </router-link>
                <!-- <div class="flex-center">
                    <img src="https://file.jinglinshe.com/kittyhow/精灵国.png" alt="" srcset="">
                    <span>精灵国英语学习</span>
                </div> -->
            </div>
        </div>
        <div class="right">
            <div class="tit flex-center">
                <div></div>内容变现解决方案</div>
            <div class="detail flex-center">
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/live" tags="span">短视频售卖</router-link>
                </div>
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/live" tags="span">直播间售卖</router-link>
                </div>
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/marketing" tags="span">多元营销</router-link>
                </div>
            </div>
            <div class="tit tit2 flex-center">
                <div></div>
                <router-link @click.native="$emit('closeProduct')" to="/more" tags="span">更多服务</router-link>
            </div>
            <div class="detail flex-center">
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/serve" tags="span">数据诊断</router-link>
                </div>
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/serve" tags="span">担保交易</router-link>
                </div>
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/serve" tags="span">版权保护</router-link>
                </div>
            </div>
            <div class="detail flex-center">
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/serve" tags="span">包装设计</router-link>
                </div>
                <div>
                    <router-link @click.native="$emit('closeProduct')" to="/serve" tags="span">专属客服</router-link>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import {
        ref,
        props
    } from 'vue';
    export default {
        setup(props) {
            const top = ref(props.top);
            return {
                top
            };
        },
        props: {
            top: {
                type: String,
                required: true
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import "../styles/color.scss";
    .productBox {
        left: calc(50vw - 648px);
        position: fixed;
        top: 30px;
        margin-top: 20px;
        z-index: 1;
        border-radius: 0px 0px 12px 12px;
        padding: 30px 0;
        height: 380px;
        box-sizing:border-box;
        .left {
            font-size: 17px;
            border-right: 1px solid rgba(69, 71, 77, 0.12);
        }
        .top {
            left: calc(50% - 50px);
            top: -12px; // left: calc(50% + 20px);
            z-index: 0;
            padding: 0;
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            /* 左边透明 */
            border-right: 10px solid transparent;
            /* 右边透明 */
            border-bottom: 10px solid $pink;
            /* 底部为黑色 */
        }
        &>div {
            font-size: 16px;
            padding: 0 53px;
        }
        .right {
            width: 100%;
            .tit2 {
                margin-top: 32px;
            }
            .detail {
                &>div,a {
                    display:black;
                    height:48px;
                    //margin-bottom:21px;
                    text-align: left;
                    width: 200px; //margin-right: 110px;
                }
                a{
                    display:inline-block
                }
                line-height: 28px;
            }
        }
        .left {
            border-right: 1px solid 1px solid rgba($gray45474d, 0.12);
            width: 365px;
        }
        .cont {
            text-align: left;
            &>div,
            &>a {
                margin-bottom: 12px;
            }
            img {
                margin-right: 16px;
                width: 50px;
                height: 50px;
            }
        }
        .tit {
            font-size: 15px;
            padding-bottom: 30px;
            color: $gray7A7A7A;
            div {
                margin-right: 10px;
                width: 4px;
                height: 20px;
                background-color: $pink;
                text-align: center;
            }
            span,
            a {
                color: $gray7A7A7A;
            }
        }
        color:$gray2D2E3A;
        height: 380px;
        width:1280px;
        line-height: 20px;
        border-radius: 0px 0px 12px 12px;
        background-color: rgba($white, 1);
        text-align: center;
        box-shadow: 0px 4px 6px 5px rgba($gray45474d, 0.42);
        border-top: 2px solid rgba(254, 52, 110, 1);
    }
</style>